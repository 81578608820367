import { useEffect, useState } from "react";
import {
  BsFillArrowUpCircleFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import { LightTooltip } from "./ToolTipCustom";
import { useStateContext } from "../../../contexts/ContextProvider";
import "./ScrollBoth.css";

export default function ScrollButton({ direction, tooltipTitle }) {
  const { currentColor } = useStateContext();

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: direction === "top" ? 0 : document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <LightTooltip
          title={tooltipTitle}
          placement="top"
          currentColor={currentColor}
        >
          <div
            className={`scroll-${direction} right-2 bottom-10 z-50   `}
            onClick={scrollToTop}
            style={{ color: currentColor }}
          >
            {direction === "top" ? (
              <BsFillArrowUpCircleFill className=" w-8 h-8 rounded-full   hover:animate-bounce" />
            ) : (
              <BsFillArrowDownCircleFill className="w-8 h-8  rounded-full   hover:animate-bounce" />
            )}
          </div>
        </LightTooltip>
      )}
    </>
  );
}
