import { useState } from "react";
import { Link } from "react-router-dom";
import Image from "../components/designLayouts/Image";
import { notfound } from "../assets/images";
import Header from "../components/home/Header/Header";
import { useStateContext } from "../contexts/ContextProvider";

const NotFoundPage = () => {
  const { currentColor } = useStateContext();
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const opacityValue = isHovered ? 0.8 : 1;

  return (
    <>
      <Header />
      <div className="flex flex-col h-screen justify-center items-center">
        <div className="xl:w-[30%]  lgl:w-[30%] lg:w-[40%] md:w-[50%]  sm:w-[100%] xs:w-full xxs:w-full  animate-bounce duration-300 ease-in-out ">
          <Image imgSrc={notfound} />
        </div>

        <h1 className="text-4xl font-bold text-center text-darkColorFlop dark:text-white drop-shadow-sm  mb-4">
          Oops! Page not found
        </h1>
        <p className="text-lg text-center font-medium text-darkColorFlop dark:text-white drop-shadow-sm mb-8">
          We couldn't find the page <br />{" "}
          <span className="font-light">you were looking for.</span>
        </p>
        <div className="text-white">
          <Link to="/">
            <button
              className="inline-block px-6 py-2 font-bold  hover:tracking-tight   rounded-md shadow-lg drop-shadow-lg  "
              href="/"
              style={{
                backgroundColor: `${currentColor}`,
                opacity: opacityValue,
                transition: "opacity 0.3s ease",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Go back to Home
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
