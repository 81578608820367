// UserContext.js
import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();



export const UserProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userNumber, setUserNumber] = useState('');
  

  const login = (name) => {
    setIsLoggedIn(true);
    setUserName(name);
    // localStorage.setItem('isLoggedIn', true);
    localStorage.setItem('userName', name);
    localStorage.setItem('isLoggedIn', true);
  };
  const phone = (number) => {
    setIsLoggedIn(true);
    setUserNumber(number);
    // localStorage.setItem('isLoggedIn', true);
    localStorage.setItem('userNumber', number);
    localStorage.setItem('isLoggedIn', true);
  };
  
  
  
  const logout = () => {
    setIsLoggedIn(false);
    setUserName('');
    setUserNumber('');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userName');
    localStorage.removeItem('userNumber');
    localStorage.removeItem('email');
    localStorage.removeItem('mobile');
    
  };


  return (
    <UserContext.Provider value={{ isLoggedIn, userName, login, userNumber, phone, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
