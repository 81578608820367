import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { flopLogoLight } from "./assets/images";

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  const consentContainerRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();

    setTimeout(() => {
      tl.fromTo(
        consentContainerRef.current,
        { y: 100, duration: 6, scale: 2, ease: "power2.out", opacity: 0 },
        { y: 0, duration: 2, ease: "elastic.out", scale: 1, opacity: 1 }
      );
    }, 10000);

    return () => tl.kill();
  }, []);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setTimeout(() => {
        setShowConsent(true);
      }, 10000);
    }
  }, []);

  const acceptCookies = () => {
    const tl = gsap.timeline();

    
    tl.to(consentContainerRef.current, {
      duration: 1.5, 
      scale: 0.2, 
      y: -1000, 
      opacity: 1,
      ease: "power2.inOut", 
      onComplete: () => setShowConsent(false), 
    });

    tl.play(); 
    localStorage.setItem("cookieConsent", "accepted");
    return () => tl.kill();
  };

  const denyCookies = () => {
    const tl = gsap.timeline();

    
    tl.to(consentContainerRef.current, {
      duration: 1.5, 
      scale: 0.2,
      y: -1000, 
      opacity: 1,
      ease: "power1.out", 
      onComplete: () => setShowConsent(false), 
    });

    tl.play(); 
    localStorage.setItem("cookieConsent", "denied");
    return () => tl.kill();
  };

  return (
    <>
      <div
        ref={consentContainerRef}
        className="fixed bottom-0 left-0 right-0 text-white p-4 flex flex-col items-center z-50"
      >
        {showConsent && (
          <div className="fixed bottom-0 left-0 right-0 text-white p-4 flex flex-col  items-center  z-50">
            <div className="group  bg-black bg-opacity-90 shadow-lg transition duration-300 hover:bg-opacity-100 hover:scale-x-110 hover:scale-y-110 hover:shadow-2xl rounded-lg p-4 w-auto">
              <div className="flex-1 items-center justify-center content-center">
                <div className="flex justify-center items-center">
                  <img
                    className="w-12 h-12 group-hover:w-16 group-hover:h-16"
                    src={flopLogoLight}
                    alt="Logo"
                  />
                </div>
                <div className="flex justify-center mt-4">
                  <span className="text-white font-bold">
                    We uses cookies to enhance Your experience.
                  </span>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <button
                  onClick={acceptCookies}
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold px-4 py-2 rounded focus:outline-none mr-2"
                >
                  Accept
                </button>
                <button
                  onClick={denyCookies}
                  className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded focus:outline-none"
                >
                  Deny
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CookieConsent;
