import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";


import { FcCollapse } from "react-icons/fc";
import { FcExpand } from "react-icons/fc";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";

import Image from "../../designLayouts/Image";

import "aos/dist/aos.css";

import "../../../App.css";

import { motion } from "framer-motion";
import { flopLogoLight, flopLogoDark } from "../../../assets/images";
import { navBarList } from "../../../constants";
import Flex from "../../designLayouts/Flex";
import { useStateContext } from "../../../contexts/ContextProvider";


const Header = () => {
  const {t} = useTranslation();
  const { currentMode, currentColor } = useStateContext();

  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const [category, setCategory] = useState(true);
  const products = useSelector((state) => state.flopReducer.products);
  const [isVisible, setIsVisible] = useState(false);

  const location = useLocation();
  useEffect(() => {
    let ResponsiveMenu = () => {
      if (window.innerWidth < 667) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
     
        <div className="high-light w-full h-20 bg-white dark:bg-main-dark-bg sticky top-0 z-50 border-b-[0px] border-b-gray-200">
          <nav className="h-full px-4 max-w-container mx-auto relative">
            <Flex className="flex items-center justify-between h-full">
              <Link to="/">
                <div>
                  <Image
                    className=" w-20 object-cover"
                    imgSrc={
                      currentMode === "Dark" ? flopLogoLight : flopLogoDark
                    }
                  />
                </div>
              </Link>

              <div>
                {showMenu && (
                  <motion.ul
                    initial={{ y: 80, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="flex items-center w-auto z-50 p-0 gap-2"
                  >
                    <>
                      <div className="flex justify-end p-8 -right-12">
                        {navBarList.map((item) => (
                          <div key={item._id}>
                            <NavLink
                              className="flex  font-normal hover:font-light hover:tracking-tight w-20 h-6 justify-center items-center px-12 text-base  dark:text-white dark:hover:text-flopdarkColor"
                              to={item.link}
                              state={{ data: location.pathname.split("/")[1] }}
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                className="text-black dark:text-white"
                                style={{ textDecoration: "none" }}
                              >
                                {t(item.title)}
                              </li>
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    </>
                  </motion.ul>
                )}
                <div className="flex flex-row justify-between  items-center md:hidden">
                  <div className="px-5">
                    {products.length > 0 && (
                      <Link to="/cart">
                        <div
                          className={`w-14 h-[60px]  rounded-md flex flex-col gap-1  justify-center items-center shadow-sm shadow-gray-900 dark:shadow-slate-200 overflow-x-hidden group cursor-pointer fixed ${
                            isVisible
                              ? "fixed right-20 bottom-3"
                              : "absolute right-24 top-2"
                          }`}
                          style={{
                            backgroundColor: `${currentColor}`,
                            color: "white",
                          }}
                        >
                          <div className="flex justify-center items-center">
                            <RiShoppingCart2Fill className="text-2xl sm:text-xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200" />

                            <RiShoppingCart2Fill className="text-2xl sm:text-xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200" />
                          </div>
                          <p className="text-xs sm:text-[0.60rem] font-semibold ">
                            {t("buy_now")}
                          </p>
                          {products.length > 0 && (
                            <p
                              className="absolute top-1 right-2   text-xs w-4 h-4  rounded-full flex items-center justify-center font-semibold"
                              style={{
                                color: `${currentColor}`,
                                backgroundColor: "white",
                              }}
                            >
                              {products.length}
                            </p>
                          )}
                        </div>
                      </Link>
                    )}
                  </div>

                  <HiMenuAlt2
                    onClick={() => setSidenav(!sidenav)}
                    className="cursor-pointer w-8 h-6 text-darkColorFlop dark:text-lightColor"
                  />
                </div>
                {sidenav && (
                  <div className="fixed top-0 left-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
                    <motion.div
                      initial={{ x: -300, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      className="w-[80%] h-full relative"
                    >
                      <div className="w-full h-full bg-primeColor/95 p-6">
                        <img
                          className="w-28 mb-6"
                          src={flopLogoLight}
                          alt="logoLight"
                        />
                        <ul className="text-gray-200 flex flex-col gap-2">
                          {navBarList.map((item) => (
                            <li
                              className="font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                              key={item._id}
                            >
                              <NavLink
                                to={item.link}
                                state={{
                                  data: location.pathname.split("/")[1],
                                }}
                                onClick={() => setSidenav(false)}
                              >
                                {t(item.title)}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-4">
                          <h1
                            onClick={() => setCategory(!category)}
                            className="flex justify-between text-base cursor-pointer items-center  mb-2"
                          >
                            {t("shop_by_category")}
                            <span className="text-lg">
                              {category ? <FcCollapse /> : <FcExpand />}
                            </span>
                          </h1>
                          {category && (
                            <motion.ul
                              initial={{ y: 15, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ duration: 0.4 }}
                              className="text-sm flex flex-col gap-1"
                            >
                              <Link to="/men">
                                <li className="headerSedenavLi">{t("men")}</li>
                              </Link>
                              <Link to="/women">
                                <li className="headerSedenavLi">{t("women")}</li>
                              </Link>

                              <Link to="/kid">
                                <li className="headerSedenavLi">{t("kid")}</li>
                              </Link>
                            </motion.ul>
                          )}
                        </div>
                      </div>
                      <span
                        onClick={() => setSidenav(false)}
                        className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 right-2 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                      >
                        <MdClose />
                      </span>
                    </motion.div>
                  </div>
                )}
              </div>
            </Flex>
          </nav>
        </div>
      
    </>
  );
};

export default Header;
