import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  userInfo: {},
  products: [],
  user: null,
  authUser: false,
};

export const flopSlice = createSlice({
  name: "flop",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.products.find(
        (item) => item._id === action.payload._id
      );
      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.products.push(action.payload);
        toast.success(`${action.payload.name} added to Cart`);
      }
    },
    increaseQuantity: (state, action) => {
      const item = state.products.find(
        (item) => item._id === action.payload._id
      );
      if (item) {
        item.quantity++;
        toast.success(`Item QTY Increased`);
      }
    },
    drecreaseQuantity: (state, action) => {
      const item = state.products.find(
        (item) => item._id === action.payload._id
      );
      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
        toast.success(`Item QTY Decreased`);
      }
    },
    deleteItem: (state, action) => {
      const itemToRemove = state.products.find(
        (item) => item._id === action.payload
      );
      state.products = state.products.filter(
        (item) => item._id !== action.payload
      );
      if (itemToRemove) {
        toast.success(`${itemToRemove.name} Removed From Cart`);
      }
    },

    resetCart: (state) => {
      state.products = [];
      toast.success(`Cart Cleared`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    loginUser: (state, action) => {
      state.user = action.payload;
    },
    logoutUser: (state) => {
      state.user = null;
    },
    setAuthUser: (state, action) => {
      // New action to set authUser
      const user = action.payload;
      // Transform user to ensure it's serializable
      state.authUser = true;
    },
    clearAuthUser: (state) => {
      // New action to clear authUser
      state.authUser = false;
    },
  },
});

export const {
  addToCart,
  increaseQuantity,
  drecreaseQuantity,
  deleteItem,
  resetCart,
  updateUserInfo,
  loginUser,
  logoutUser,
  setAuthUser,
  clearAuthUser,
} = flopSlice.actions;
export default flopSlice.reducer;
