import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import ProductsOnSale from "../../components/pageProps/productDetails/ProductsOnSale";
import "aos/dist/aos.css";

import ProductImage from "./ProductImage.jsx";

import ProductImageGallery from "../../components/Zoom/ZoomM";

const ProductDetails = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState([]);

  useEffect(() => {
    const item = location.state.item;
    // console.log("🚀 ~ useEffect ~ item:", item)
    setProductInfo(item);
    setPrevLocation(location.pathname);
  }, [location]);

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300 bg-lightColor dark:bg-main-dark-bg">
    
      <ProductImage  />
     

      <div className="max-w-container mx-auto px-4   ">
        <div className="xl:-mt-10 -mt-7">
          <Breadcrumbs title="" prevLocation={prevLocation} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 h-full -mt-5 xl:-mt-8 pb-10 p-4">
          <div className="h-full xl:col-span-2 flex flex-col gap-6 justify-center" data-aos="flip-left"
    data-aos-easing="ease-out-quad"
    data-aos-duration="2000"  data-aos-once="true">
            <ProductImageGallery images={productInfo.images} />
          </div>
        </div>
        <div className="h-full">
          <ProductInfo productInfo={productInfo} />
        </div>

        {/* <div className="h-full">
          <ProductsOnSale />
        </div> */}
      </div>
    </div>
  );
};

export default ProductDetails;
