import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import toast from "react-hot-toast";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import { FaMobile, FaWhatsapp } from "react-icons/fa";
import { MdMail } from "react-icons/md";

const Footer = () => {
  const [emailInfo, setEmailInfo] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const { currentColor } = useStateContext();

  const emailValidation = () => {
    return String(emailInfo)
      .toLocaleLowerCase()
      .match(/^[\w._]+([-]?[\w._]+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSubscription = async () => {
    const username = emailInfo.split("@")[0];
    const formattedUsername =
      username.charAt(0).toUpperCase() + username.slice(1);
    const emailData = {
      email: emailInfo,
      username: formattedUsername,
    };
    const storeEmailsInFirebase = async (emailData) => {
      const db = getFirestore();
      try {
        const docRef = await addDoc(collection(db, "emails"), emailData);

        setSuccessMsg(`Thank you ${formattedUsername} for subscribing !`);
        toast.success(`Document written with ID: ${docRef.id} `);
      } catch (error) {
        
        toast.error("Error adding document:", error);
      }
    };

    if (emailInfo === "") {
      setErrMsg("Please provide an Email !");
      toast.error("Please give a valid Email!");
    } else if (emailValidation(emailInfo)) {
      setSuccessMsg(`Thank you ${formattedUsername} for subscribing !`);
      setSubscription(true);
      toast.success(`Thank you ${formattedUsername}`);
      storeEmailsInFirebase(emailData);
    } else if (!emailValidation(emailInfo)) {
      setErrMsg("Please give a valid Email!");
      toast.error("Please give a valid Email!");
    } else {
      setSubscription(true);
      setErrMsg("");
      setEmailInfo("");
    }
  };

  return (
    <div className="w-full  py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-2">
          <FooterListTitle title=" More about Flop Stores" />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%] text-black dark:text-white">
              168 Abd El-Salam Aref, San Stifano, Qism El-Raml , Alexandria,
              Egypt
            </p>
            <ul className="flex items-center gap-2">
              <a
                href="https://www.facebook.com/FlopStores"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
              <a
                href="https://www.instagram.com/flop.egypt/"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaInstagram />
                </li>
              </a>
            </ul>
          </div>
        </div>

        <div className="text-darkColorFlop dark:text-white">
          <FooterListTitle title="Shop" />
          <ul className="flex flex-col gap-2">
            <Link to="/men">
              <li className=" text-base  hover:text-black  dark:hover:text-lightText hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Men
              </li>
            </Link>
            <Link to="/women">
              <li className=" text-base  hover:text-black  dark:hover:text-lightText hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Women
              </li>
            </Link>
            <Link to="/kid">
              <li className=" text-base  hover:text-black  dark:hover:text-lightText hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Kid
              </li>
            </Link>
          </ul>
        </div>
        <div className="text-darkColorFlop dark:text-white">
          <FooterListTitle title="Your account" />
          <ul className="flex flex-col gap-2">
            <Link to="/profile">
              <li className=" text-base  hover:text-black  dark:hover:text-lightText hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Profile
              </li>
            </Link>
            <Link to="/profile">
              <li className=" text-base  hover:text-black  dark:hover:text-lightText hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Orders
              </li>
            </Link>
            <li className=" text-base  hover:text-black  dark:hover:text-lightText hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Addresses
            </li>
            <li className=" text-base  hover:text-black  dark:hover:text-lightText hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Account Details
            </li>
            <li className=" text-base  hover:text-black  dark:hover:text-lightText hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Payment Options
            </li>
          </ul>
        </div>
        <div className="col-span-2 flex flex-col items-center w-full px-4">
          <FooterListTitle title="Important Links" />
          <div className="flex flex-col text-darkColorFlop dark:text-white items-center ">
            <Link to={"/refund-and-exchange"}>Refund and exchange</Link>
            <Link to={"/privacy-policy"}>Privacy policy</Link>
            <FooterListTitle title="contact us at" />
            <ul className="flex flex-col items-center  gap-2 font-bodyFont font-semibold tracking-widest">
              <li>
                <a href="tel:01028177122">
                  <span className="flex items-center justify-center ">
                    <FaMobile />
                  </span>
                  01028177122
                </a>
              </li>
              <li>
                <a href="mailto:flopegypt@gmail.com">
                  <span className="flex items-center justify-center ">
                    <MdMail />
                  </span>
                  flopegypt@gmail.com
                </a>
              </li>
              <li>
                <a href="https://wa.me/+201028177122">
                  <span className="flex items-center justify-center ">
                  <FaWhatsapp />

                  </span>
                  Send Whatapp message
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-span-2 flex flex-col items-center w-full px-4">
          <FooterListTitle title="Subscribe to our newsletter." />
          <div className="w-full">
            <p className="text-center mb-4 text-[#6d6d6d]">
              It's our pleasure To Hear From You
            </p>
            {subscription ? (
              <motion.p
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full text-center text-base  font-semibold text-green-600"
              >
                Subscribed Successfully !
                <br />
                {successMsg}
              </motion.p>
            ) : (
              <div className="w-full flex-col xl:flex-row flex justify-between items-center gap-4">
                <div className="flex flex-col w-full">
                  <input
                    onChange={(e) => setEmailInfo(e.target.value)}
                    value={emailInfo}
                    className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-[#6d6d6d] text-lg placeholder:text-base outline-none"
                    type="text"
                    placeholder="Insert your email ...*"
                  />
                  {errMsg && (
                    <p className="text-red-600 text-sm font-semibold  text-center animate-bounce mt-2">
                      {errMsg}
                    </p>
                  )}
                </div>
                <button
                  onClick={handleSubscription}
                  className="  w-[30%] h-10   duration-300 text-base tracking-wide rounded"
                  style={{ backgroundColor: currentColor, color: "#fff" }}
                >
                  Subscribe
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
