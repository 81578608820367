import React from "react";
import { MdLocalShipping } from "react-icons/md";
import { useTranslation} from "react-i18next"

const BannerBottom = () => {
  const {t} = useTranslation();
 

  return (
    <div className="w-full bg-white dark:bg-main-dark-bg  text-black dark:text-lightColor py-4 px-4">
      <div className="max-w-container mx-auto h-20 flex flex-col md:flex-row justify-around items-center">
        <div className="flex items-center gap-2 w-72 shadow-sm hover:shadow-md duration-300">
          <span className="font-bold w-6 text-center pr-10 animate-bounce2">
            100%
          </span>
          <p className=" text-base hover:tracking-wide">{t("quality_warranty")}</p>
        </div>
        <div className="flex md:w-auto items-center gap-2 w-72 shadow-sm hover:shadow-md duration-300 ">
          <span className="text-xl text-center w-6 ml-1">
            <MdLocalShipping className="animate-moveCar duration-300" />
          </span>
          <p className="text-base hover:tracking-wide">
           {t("return_policy")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BannerBottom;
