import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { createTheme, styled } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#314D95",
      contrastText: "#47008F",
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      contrastText: "#47008F",
    },
  },
});

export const LightTooltip = styled(({ currentColor, className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, currentColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: currentColor || theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[20],
    fontSize: 15,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: currentColor || theme.palette.primary.main,
  },
}));
