import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchProducts = createAsyncThunk(
  "product/fetchProducts",
  async (_, { getState, rejectWithValue }) => {
    const state = getState().product;
    const now = Date.now();
    const fiveMinutes = 5 * 60 * 1000;

    // Handle potential undefined state
    if (state && state.lastFetched && now - state.lastFetched < fiveMinutes) {
      return rejectWithValue("Data is still fresh"); // Reject fetch if data is fresh
    }

    try {
      const response = await axios.get(
        "https://osuuggvwikirmwtaycon.supabase.co/functions/v1/variants"
      );
      return { data: response.data, fetchedAt: now };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const initialState = {
  items: [],
  isSuccess: false, // Flag for successful fetch
  isError: null, // Holds error message if any
  status: "idle",
  lastFetched: null, // Timestamp of the last successful fetch
};

const productsSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
        state.isSuccess = false; // Reset success flag
        // state.isError = null;   // Reset error message
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = transformData(action.payload.data);
        state.isSuccess = true;
        state.isError = null; // Set success flag on successful fetch
        // state.error = ""
        state.lastFetched = action.payload.fetchedAt;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.isError = action.error.message;
      });
  },
});

export default productsSlice.reducer;

const transformData = (data) => {
  if (!Array.isArray(data)) {
    console.error("Received unexpected data format. Expected an array.");
    return []; // Or handle the empty response in another way
  }
  const productsMap = {};

  data.forEach((item) => {
    if (item.quantity > 0) {
      const {
        barCode,
        price,
        color,
        category,
        season,
        sizeName,
        badge,
        ...rest
      } = item;

      if (!productsMap[barCode]) {
        productsMap[barCode] = {
          barCode,
          price,
          category,
          season,
          badge,
          variants: [],
          colors: [],
          sizes: [],
          codes: [],
        };
      }

      const variant = {
        ...rest,
        color,
        price,
        barCode,
        season,
        badge,
        sizeName,
        category,
      };

      productsMap[barCode].variants.push(variant);
      productsMap[barCode].colors.push(color);
      productsMap[barCode].sizes.push(sizeName);
      productsMap[barCode].codes.push(variant.code);
    }
  });

  Object.values(productsMap).forEach((product) => {
    product.variants.sort((a, b) => a.id - b.id);
  });
  // console.log(Object.values(productsMap))

  return Object.values(productsMap);
};
