import React from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import {
  deleteItem,
  drecreaseQuantity,
  increaseQuantity,
} from "../../redux/flopSlice";
import { useStateContext } from '../../contexts/ContextProvider';

const ItemCard = ({ item }) => {
  // console.log("🚀 ~ ItemCard ~ item:", item)
  const { currentColor } = useStateContext();
  const dispatch = useDispatch();
  function financial(x) {
    return Number.parseFloat(x).toFixed(2);
  }
  return (
    <div className="w-full grid grid-cols-5 mb-4 border py-2 rounded">
      <div className="flex col-span-5 mdl:col-span-2 items-center gap-4 ml-4">
        <ImCross
          onClick={() => dispatch(deleteItem(item._id))}
          className="text-darkColorFlop dark:text-white hover:text-red-500 duration-300 cursor-pointer"
        />
        <img className="w-32 h-32 object-contain" src={item.images} alt={item.name} />
        <h1 className=" font-semibold">{item.name}</h1>
        <h1 className="flex flex-col p-4 m-4  font-semibold">{item.color}</h1>
      </div>
      <div className="col-span-5 mdl:col-span-3 flex items-center justify-between py-4 mdl:py-0 px-4 mdl:px-0 gap-6 mdl:gap-0">
        <div className="flex w-1/3 items-center text-lg font-semibold">
          EGY {item.price}
        </div>
        
        <div className="w-1/3 flex items-center gap-6 text-lg">
          <span
            onClick={() => dispatch(drecreaseQuantity({ _id: item._id }))}
            className={`w-6 h-6 text-white text-2xl flex items-center justify-center hover:bg-gray-300 cursor-pointer duration-300 border-[1px] border-gray-300 hover:border-gray-300 select-none rounded`}
            style={{ backgroundColor: currentColor }}
          >
            -
          </span>
          <p>{item.quantity}</p>
          <span
            onClick={() => dispatch(increaseQuantity({ _id: item._id }))}
            className="w-6 h-6 text-white text-2xl flex items-center justify-center hover:bg-gray-300 cursor-pointer duration-300 border-[1px] border-gray-300 hover:border-gray-300 select-none rounded"
            style={{ backgroundColor: currentColor }}
          >
            +
          </span>
        </div>
        <div className="w-1/3 flex items-center  font-bold text-lg">
          <p>EGY {financial(item.quantity * item.price)}</p>
        </div>
      </div>
      <div className="col-span-5  flex items-center justify-between py-4 mdl:py-1 px-4 mdl:px-1 gap-6 mdl:gap-0">
        <p className="font-bold text-lg">Size {item.size}</p>
        <p className="font-bold text-lg">Code {item.code}</p>
      </div>
      
    </div>
    
  );
};

export default ItemCard;
