import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Header from '../../components/home/Header/Header'
import Footer from '../../components/home/Footer/Footer'
import FooterBottom from "../../components/home/Footer/FooterBottom";

const Refund = () => {
 
  
  return (
    <div className="relative max-w-container h-[100vh] mx-auto px-4">
    <div className="absolute w-full">
    <Header />  
    </div>
      <div className="relative top-11">
        <Breadcrumbs title="Refund and Exchanges Policy"  />
      <div className="pb-10">
      <div className=" bg-gray-100 dark:bg-main-dark-bg-lighter text-gray-700 dark:text-white p-4 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Refund and Exchanges Policy</h2>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Refund</h3>
        <p >
          Items can be returned for a full refund within **14 days** of the invoice date, provided that the item is in the same condition as it was purchased and the original invoice is presented.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Exchanges</h3>
        <p className="">
          Items can be exchanged for a different size or color within **14 days** of the invoice date, provided that the item is in the same condition as it was purchased and the original invoice is presented. The customer is responsible for the shipping costs of exchanges.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Warranty</h3>
        <p >
          The warranty for products sold covers manufacturing defects only for a period of **14 days** from the invoice date. The warranty is valid only if the washing instructions are followed and the original invoice is presented.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Exceptions</h3>
        <p >
          Swimsuits, watches, and underwear cannot be returned or exchanged for health reasons.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Sale Periods and Holidays</h3>
        <p >
          Returns and exchanges are not allowed during sale periods and holidays.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Shipping Costs</h3>
        <p >
          The customer is responsible for the shipping costs of returns and exchanges.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Refund Method</h3>
        <p >
          Refunds can be made in cash or by credit card.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Exchange Method</h3>
        <p >
          Exchanges can be made in person or by mail.
        </p>
      </section>
    </div>
      </div>
      <Footer />
      <FooterBottom />
      </div>
    </div>
  );
};

export default Refund;
