import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";

import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import State from "./pages/Cart/State";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Help from "./pages/Help/Help";
import Offer from "./pages/Offer/Offer";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
// import Navnew from "./components/Banner/Navnew";
import ScrollBoth from "./components/designLayouts/buttons/ScrollBoth";
import Cookies from "./Cookies";
import { LightTooltip } from "./components/designLayouts/buttons/ToolTipCustom";
import { FaSun, FaMoon, FaWhatsapp } from "react-icons/fa";
import { MenComponent, WomenComponent, KidComponent } from "./pages/Categories";
import NotFoundPage from "./pages/NotFoundPage";
import PhoneAuth from "./pages/Account/PhoneAuth";
import UserProfile from "./pages/Home/UserProfile";
import ThemeSettings from "./components/ThemeSettings";
import { useStateContext } from "./contexts/ContextProvider";
import { FiSettings } from "react-icons/fi";
import ConnectionStatusToast from "./components/utils/ConnectionStatusToast";
import Refund from "./pages/Home/Refund";
import Privacy from "./pages/Home/Privacy";

import LanguageSelector from "./components/LanguageSelector/LanguageSelector";
import Chat from "./components/utils/Chat"

 

Aos.init({
  duration: 1200,
  once: true,
});


const Layout = () => {
  return (
    <>

      
      <Cookies />
      {/* <Chat /> */}
      <Header />
      <HeaderBottom />
      {/* <Navnew /> */}
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/help" element={<Help />}></Route>
          <Route path="/men" element={<Home /> }></Route>
      <Route path="/women" element={<Home />}></Route>
      <Route path="/kid" element={<Home />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/offer" element={<Offer />}></Route>
        <Route
          path="/product/:_id"
          element={<ProductDetails />}
          errorElement={<Navigate to="/not-found" replace />}
        ></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/state" element={<State />}></Route>
      </Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
      <Route path="/mobile" element={<PhoneAuth />}></Route>
      <Route path="/profile" element={<UserProfile />}></Route>
    
      <Route path="/*" element={<Navigate to="/not-found" replace />} />
      <Route path="/not-found" element={<NotFoundPage />} />
      <Route path="/refund-and-exchange" element={<Refund />} />
      <Route path="/privacy-policy" element={<Privacy />} />
    </Route>
  )
);

function App() {
  const { t } = useTranslation();

  const fontChange = localStorage.getItem("i18nextLng");
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor || currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    } else {
      setCurrentColor("#104d9c");
      setCurrentMode("Light");
    }
  }, []);

  return (
    <>
      <ConnectionStatusToast />

      <div className={currentMode === "Dark" ? "dark" : ""}>
        {themeSettings && <ThemeSettings />}
        {isVisible && (
          <div className="flex relative dark:bg-main-dark-bg animate-search hover:animate-none z-[2000]">
            <div className="fixed right-2 bottom-14 z-[2000]">
              <div className="absolute left-[0.2rem] bottom-16">
                <LightTooltip
                  title="Send Whatsapp Message"
                  placement="top"
                  currentColor={currentColor}
                >
                  <a href="https://wa.me/+201028177122">
                    <FaWhatsapp className="text-5xl  text-green-500 hover:animate-bounce2 duration-300 ease-in-out " />
                  </a>
                </LightTooltip>
              </div>

              <LightTooltip
                title="Settings"
                placement="top"
                currentColor={currentColor}
                className="py-4"
              >
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: "50%" }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:scale-105"
                >
                  <FiSettings className="hover:animate-spinSlow duration-300 ease-in-out " />
                </button>
              </LightTooltip>
            </div>
          </div>
        )}
        <div
          className={` ${
            fontChange === "ar" ? "font-bodyFontAr" : "font-bodyFont"
          }  bg-white dark:bg-[#1e1e1e]
        h-full min-w-screen overflow-x-hidden `}
        >
          <RouterProvider router={router} />
          <LanguageSelector />
        </div>
      </div>

      <LightTooltip
        currentColor={currentColor}
        title={t("theme")}
        placement="top"
        arrow
      >
        <button
          className="absolute top-8 right-12  z-50 lg:top-8 lg:right-2 md:top-8 md:right-2 sml:top-8 sml:right-12 sm:top-8 sm:right-12"
          onClick={() => setThemeSettings(true)}
        >
          {currentMode === "Dark" ? (
            <FaMoon className="text-lightColor" />
          ) : (
            <FaSun className="text-darkColorFlop" />
          )}
        </button>
      </LightTooltip>

      <ScrollBoth direction="top" tooltipTitle="To Top" arrow />
      <ScrollBoth direction="bottom" tooltipTitle="To Bottom" arrow />
    </>
  );
}

export default App;
