import React from "react";



const NavTitle = ({ title }) => {

  return (
    <div className="flex items-center justify-between pb-5 text-darkColorFlop dark:text-white">
    
          <h3 className="font-bold lg:text-xl ">{title}</h3>
       
    </div>
  );
};

export default NavTitle;
