import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa";
import { LightTooltip } from "../designLayouts/buttons/ToolTipCustom";
import { useStateContext } from "../../contexts/ContextProvider";

const languages = [
  {
    lang: "English",
    code: "en",
    country_code: "gb",
  },
  {
    lang: "Arabic",
    code: "ar",
    country_code: "eg",
  },
];

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();
  
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const {  currentColor } =
    useStateContext();

  const changeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };

  useEffect(() => {
    document.title = t("app_title");
    document.documentElement.lang = currentLanguage;
  }, [i18n, t, currentLanguage]);

  return (
      <LightTooltip currentColor={currentColor} title={currentLanguage === "en" ? "العربية" : "English"}  placement="top" arrow >
    <button
      onClick={() => changeLanguage()}
      className="absolute top-8 right-12  z-50 lg:top-8 lg:right-7 md:top-8 md:right-7 sml:top-8 sml:right-[4.5rem] sm:top-8 sm:right-[4.5rem]"
    >
        <FaGlobe className="text-darkColorFlop dark:text-lightColor" />
    </button>
      </LightTooltip>
  );
};

export default LanguageSelector;
