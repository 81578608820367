import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";

import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import YearProduct from "../../components/home/YearProduct/YearProduct";


// import { Sales, Hero, Stories, FlexContent } from "../../components";

// import {
//   heroapi,
//   popularsales,
//   toprateslaes,
//   highlight,
//   story,
//   sneaker,
// } from "../../data/data.js";
import toast from "react-hot-toast";

import { useStateContext } from "../../contexts/ContextProvider";
import { useDispatch } from "react-redux";
import { clearAuthUser, logoutUser } from "../../redux/flopSlice";

import { useTranslation } from "react-i18next";
import ProductsListRedux from "../../ProductsListRedux";
// import { useLanguage } from '../../LanguageContext';
const Home = () => {
  const { t } = useTranslation();
  const fontChange = localStorage.getItem("i18nextLng");

  const { currentColor } = useStateContext();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { userName, login, userNumber, phone, logout } = useUser();

  const userMobile = localStorage.getItem("mobile");

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const userEmail = localStorage.getItem("email");
    const userMobile = localStorage.getItem("mobile");
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (authToken) {
      login(userEmail); // Assuming you want to use userEmail as the user's name
    } else if (userMobile) {
      phone(userMobile);
    }
  }, [login, phone]);

  let userNameWithoutDomain = "";

  if (userName) {
    const userNameSplit = userName.split("@");
    if (userNameSplit.length > 0) {
      userNameWithoutDomain =
        userNameSplit[0].charAt(0).toUpperCase() + userNameSplit[0].slice(1);
    }
  } else if (userNumber) {
    userNameWithoutDomain = userNumber;
  }

  function removeItemsWithPrefix(prefix) {
    // Iterate over all keys in localStorage
    for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);

        // Check if the key starts with the given prefix
        if (key && key.startsWith(prefix)) {
            // Remove the item from localStorage
            localStorage.removeItem(key);
        }
    }
}

// Example usage: remove all items that start with 'profileImageUrl_'



  const signOut = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userName");
    localStorage.removeItem("email");
    localStorage.removeItem("userNumber");
    localStorage.removeItem("mobile");

    localStorage.removeItem("authToken");
    localStorage.removeItem("uid");
    removeItemsWithPrefix('profileImageUrl_');
    removeItemsWithPrefix('profileName_');
    localStorage.removeItem("gmail");
    logout();
    dispatch(logoutUser());
    dispatch(clearAuthUser());
    navigate("/");
    toast.success("Logged Out Successfully");
  };

  const renderContent = () => {
    if (fontChange === "en") {
      return userName || userMobile ? (
        <h1>
          <span>{t("welcome")}</span> {userNameWithoutDomain || userMobile}
        </h1>
      ) : (
        <button className="cursor-pointer" onClick={() => navigate("/signin")}>
          {t("please_login")}
        </button>
      );
    } else if (fontChange === "ar") {
      return userName || userMobile ? (
        <h1>
          {userNameWithoutDomain || userMobile} <span>{t("welcome")}</span>
        </h1>
      ) : (
        <button className="cursor-pointer" onClick={() => navigate("/signin")}>
          {t("please_login")}
        </button>
      );
    }
  };

  return (
    <>
      <div className="w-full">
        <div
          className={`flex flex-col justify-center items-center content-center p-2 m-2 font-bold ${
            fontChange === "ar" ? "font-bodyFontAr" : "font-bodyFont"
          }`}
        >
          <div
            className="welcome shadow-sm shadow-gray-400 dark:shadow-slate-200 "
            align="center"
            style={{
              backgroundColor: `${currentColor}`,
            }}
          >
            <div className="shining"></div>

            {renderContent()}
          </div>
          {!userName && !userMobile ? (
            ""
          ) : (
            <div className="p-2">
              <button
                className="submit shadow-sm  shadow-gray-400 dark:shadow-slate-200"
                align="center"
                onClick={signOut}
                style={{
                  backgroundColor: `${currentColor}`,
                }}
              >
                {t("signout")}
              </button>
            </div>
          )}
        </div>

        <Banner />
        <BannerBottom />
        {/* <YearProduct /> */}
        <div className="max-w-container mx-auto px-4">
          {/* <ProductsList /> */}
          <ProductsListRedux />
          {/* <NewArrivals /> */}
          {/* <BestSellers /> */}
          {/* <SpecialOffers /> */}

          {/* <main className='flex flex-col gap-16 relative pb-4'> */}
          {/* <Hero heroapi={heroapi} /> */}
          {/* <Sales endpoint={popularsales} ifExists /> */}
          {/* <Sales endpoint={toprateslaes} /> */}
          {/* <Stories story={story} /> */}
          {/* <FlexContent endpoint={sneaker} /> */}
          {/* <FlexContent endpoint={highlight} ifExists /> */}
          {/* </main> */}
        </div>
      </div>
    </>
  );
};

export default Home;
