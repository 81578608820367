

function Loader({ bg, className }) {
  return (
    <>
      <div
        style={{ backgroundColor: bg ? bg : "" }}
        className={"full-page-loader flex flex-col overflow-hidden " || className}
      >
        <div></div>
        <div className="lds-dual-ring"></div>
        <span className="wait font-semibold text-flopdarkColor p-2 m-3">
          Please Wait
        </span>
      </div>
    </>
  );
}

export default Loader;
