import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut as firebaseSignOut,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../firebase";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Assuming you're using Font Awesome icons

import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/home/Header/Header";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";

import { toast } from "react-hot-toast";

import FullPageLoader from "../../components/designLayouts/FullPageLoader.jsx";

import PhoneAuth from "./PhoneAuth";
import AnonymousSignIn from "./Guest";

import { useDispatch } from "react-redux";

import { loginUser, setAuthUser } from "../../redux/flopSlice";

const SignIn = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSignIn = (e) => {
    e.preventDefault();
    setIsLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        const provider = user.providerId;

        setSuccessMsg("Logged in successfully!");
        dispatch(
          loginUser({
            uid: user.uid,
            email: user.email,
          }),
          dispatch(setAuthUser())
        );

        localStorage.setItem("authToken", user.accessToken);
        localStorage.setItem("email", user.email);
        localStorage.setItem("uid", user.uid);

        if (!user.emailVerified) {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              toast.success(
                "Verification email sent. Please verify your email before logging in."
              );
              setIsLoading(false);
            })
            .catch((error) => {
              toast.error("Error sending verification email: ", error.message);
            });
        } else {
          navigate("/", {
            state: { message: email.substring(0, email.indexOf("@")) },
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);

        toast.error(error.message);
      });
  };

  const handlePasswordReset = () => {
    const email = document.getElementById("email-address").value;

    if (!email) {
      toast.error("Please enter your email and then Type Your EMAIL");
      return;
    }

    sendPasswordResetEmail(auth, email);
    toast.success(
      `Email sent to ${email}! Check your inbox for password reset instructions.`
    );
  };

  const googleSignIn = () => {
    setIsLoading(true);
    const provider = new GoogleAuthProvider();
    firebaseSignOut(auth);
    signInWithPopup(auth, provider)
      .then((userCredential) => {
        const credential =
          GoogleAuthProvider.credentialFromResult(userCredential);
        const user = userCredential.user;
        const token = user.accessToken;

        dispatch(
          loginUser({
            uid: user.uid,
            email: user.email,
          }),
          dispatch(setAuthUser())
        );

        localStorage.setItem("authToken", token);
        localStorage.setItem("email", user.email);
        localStorage.setItem("uid", user.uid);

        toast.success("Logged in Successfully");
        navigate("/", {
          state: { message: email.substring(0, email.indexOf("@")) },
        });
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });
  };
  const facebookSignIn = () => {
    setIsLoading(true);
    const provider = new FacebookAuthProvider();
    firebaseSignOut(auth);

    signInWithPopup(auth, provider)
      .then((userCredential) => {
        const credential =
          FacebookAuthProvider.credentialFromResult(userCredential);
        const user = userCredential.user;
        const token = user.accessToken;

        dispatch(
          loginUser({
            uid: user.uid,
            email: user.email,
          }),
          dispatch(setAuthUser())
        );
        localStorage.setItem("authToken", token);
        localStorage.setItem("email", user.email);
        localStorage.setItem("uid", user.uid);

        toast.success("Logged in Successfully");
        navigate("/", {
          state: { message: email.substring(0, email.indexOf("@")) },
        });
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });
  };

  // const signOut = (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   firebaseSignOut(auth)
  //     .then(() => {
  //       localStorage.removeItem("authToken");
  //       localStorage.removeItem("email");
  //       localStorage.removeItem("gmail");
  //       localStorage.removeItem("isLoggedIn");
  //       localStorage.removeItem("userName");
  //       localStorage.removeItem("userNumber");
  //       localStorage.removeItem("mobile");
  //       localStorage.removeItem("uid");
  //       setIsLoading(false);

  //       // navigate("/");
  //     })
  //     .catch((error) => {
  //       toast.error("Error signing out: ", error.message);
  //     });
  // };

  return (
    <>
      {isLoading && (
        <FullPageLoader bg="rgba(255, 255, 255, 0.8)"></FullPageLoader>
      )}
      <Header />

      <div className="w-full h-screen flex items-center justify-start">
        <div className="w-1/2 hidden lgl:inline-flex h-full text-black dark:text-white">
          <div className="w-[450px] h-full  px-10 flex flex-col gap-6 justify-around">
            <div className="flex flex-col gap-1 -mt-1">
              <h1 className=" text-xl font-medium">Stay sign in for more</h1>
              <p className="text-base">When you sign in, you are with us!</p>
            </div>
            <div className="w-[300px] flex items-start gap-3">
              <span className="text-green-500 mt-1">
                <BsCheckCircleFill />
              </span>
              <p className="text-base ">
                <span className=" font-semibold ">
                  Get started fast with FLOP
                </span>
                <br />
                Sign in for FLOP in just a few clicks and start shopping
                immediately.
              </p>
            </div>
            <div className="w-[300px] flex items-start gap-3">
              <span className="text-green-500 mt-1">
                <BsCheckCircleFill />
              </span>
              <p className="text-base ">
                <span className=" font-semibold ">
                  Access all FLOP products
                </span>
                <br />
                Discover products you'll love with FLOP's smart suggestions
                based on your preferences.
              </p>
            </div>
            <div className="w-[300px] flex items-start gap-3">
              <span className="text-green-500 mt-1">
                <BsCheckCircleFill />
              </span>
              <p className="text-base ">
                <span className=" font-semibold ">
                  Trusted by online Shoppers
                </span>
                <br />
                Secure online shopping you can trust.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full lgl:w-1/2 h-full">
          {successMsg ? (
            <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
              <p className="w-full px-4 py-10 text-green-500 font-medium ">
                {successMsg}
              </p>
              <Link to="/signup">
                <button
                  className="w-full h-10 bg-primeColor text-gray-200 rounded-md text-base  font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300"
                >
                  Sign Up
                </button>
              </Link>
            </div>
          ) : (
            <form className="w-full lgl:w-[500px] h-screen flex items-center justify-center text-flopdarkColor">
              <div className="px-6 py-4 w-full h-[96%] flex flex-col justify-start">
                <h1 className=" font-semibold text-2xl mdl:text-3xl mb-4">
                {t("login")}
                </h1>
                <div className="flex flex-col gap-3 ">
                  <div className="flex flex-col gap-.5 ">
                    <p className=" text-base font-semibold pb-2">{t("email")}</p>
                    <input
                      id="email-address"
                      name="email"
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="email"
                      required
                      placeholder="Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errEmail && (
                      <p className="text-sm text-red-500  font-semibold px-4">
                        <span className="font-bold italic mr-1">!</span>
                        {errEmail}
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col gap-.5 pb-2">
                    <p className=" text-base font-semibold pb-2">{t("password")}</p>
                    <div className="relative">
                      <input
                        className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        required
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    {errPassword && (
                      <p className="text-sm text-red-500  font-semibold px-4">
                        <span className="font-bold italic mr-1">!</span>
                        {errPassword}
                      </p>
                    )}
                  </div>

                  <button
                    // onClick={handleSignUp}
                    onClick={onSignIn}
                    className="bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300"
                  >
                   {t("login")}
                  </button>

                  <div>
                    <p className="text-sm text-center  font-medium">
                      {t("fast_secure")}
                    </p>
                  </div>

                  <div className="flex justify-evenly bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300">
                    <div className="max-w-[240px] m-auto  hover:scale-[110%] ease-in-out duration-300">
                      <FcGoogle size={30} onClick={googleSignIn} />
                    </div>

                    <div className="max-w-[240px] m-auto  hover:scale-[110%] ease-in-out duration-300">
                      <BsFacebook size={30} onClick={facebookSignIn} />
                    </div>
                  </div>

                  {/* <PhoneAuth /> */}
                  {/* <AnonymousSignIn /> */}

                  {/* <div className="flex justify-center items-center font-bold my-1">
                    <button
                      className="bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300"
                      onClick={signOut}
                    >
                      Logout
                    </button>
                  </div> */}
                  <div className="flex justify-center items-center font-bold my-2">
                    {localStorage.getItem("email")}
                  </div>
                </div>
                <div>
                  <p className="text-sm text-center  font-medium my-2">
                    Don't have an Account?{" "}
                    <Link to="/signup">
                      <span className="hover:text-blue-600 duration-300">
                        {t("signup")}
                      </span>
                    </Link>
                  </p>
                </div>
                <div>
                  <p
                    onClick={handlePasswordReset}
                    className="text-sm text-center  font-medium cursor-pointer hover:text-red-600 my-2"
                  >
                    {t("forget_password")}
                  </p>
                </div>
                
                <div className="flex items-center justify-between mt-44 p-4 ">
                  <Link to={"/"}>
                    <p className="text-sm  font-semibold   cursor-pointer ">
                      © FLOP
                    </p>
                  </Link>
                  <Link to={"/refund-and-exchange"}>
                    <p className="text-sm  font-semibold   cursor-pointer ">
                      Terms
                    </p>
                  </Link>
                  <Link to={"/privacy-policy"}>
                    <p className="text-sm  font-semibold   cursor-pointer ">
                      Privacy
                    </p>
                  </Link>
                </div>
                </div>
                
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default SignIn;
