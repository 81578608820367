import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { FaUser, FaCaretDown, FaShoppingCart } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { truncate } from "lodash";
import { flopLogoDark } from "../../../assets/images";

const HeaderBottom = () => {
  const { t } = useTranslation();
  const products = useSelector((state) => state.productsReducer.items);
  const productsQuantity = useSelector((state) => state.flopReducer.products);
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();
  const refShowUser = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const searchInputRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [show, ref]);

  useEffect(() => {
    const handleClickOutsideUser = (event) => {
      if (refShowUser.current && !refShowUser.current.contains(event.target)) {
        setShowUser(false);
      }
    };

    document.body.addEventListener("click", handleClickOutsideUser);

    return () => {
      document.body.removeEventListener("click", handleClickOutsideUser);
    };
  }, [showUser, refShowUser]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);

    if (e.target.value === "") {
      setFilteredProducts([]);
    } else {
      setFilteredProducts(
        products.filter((product) =>
          product.barCode.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

const handleKeyDown = (e) => {
  if (e.key === "ArrowDown" && selectedItemIndex < filteredProducts.length - 1) {
    setSelectedItemIndex((prevIndex) => prevIndex + 1);
  } else if (e.key === "ArrowUp" && selectedItemIndex > 0) {
    setSelectedItemIndex((prevIndex) => prevIndex - 1);
  } else if (e.key === "Enter" && selectedItemIndex !== -1) {
    const selectedProduct = filteredProducts[selectedItemIndex];
    setSearchQuery(selectedProduct.barCode);
    searchInputRef.current.blur();

    navigate(
      `/product/${filteredProducts[selectedItemIndex].barCode
        .toLowerCase()
        .split(" ")
        .join("")}`,
      {
        state: {
          item: {_id:selectedProduct.barCode,
          barCode:selectedProduct.fullName,
          productName:selectedProduct.barCode,
          code:selectedProduct.variants[0].code,
          codes:selectedProduct.codes,
          color:selectedProduct.variants[0].color,
          colors:selectedProduct.colors,
          price:selectedProduct.price,
          brand:selectedProduct.category,
          size:selectedProduct.variants[0].sizeName,
          sizes:selectedProduct.sizes,
          desc:selectedProduct.season,
          quantity:selectedProduct.variants[0].quantity,
          img:
          selectedProduct?.variants[0]?.imagesUrls?.[0] ?? flopLogoDark,
          
          images:
            selectedProduct?.variants[0].imagesUrls
              ? selectedProduct?.variants[0].imagesUrls
              : [flopLogoDark],}
        },
      }
    );
    setShowSearchBar(false);
    setSearchQuery("");
    setSelectedItemIndex(-1);
  } else if (e.key === "Backspace") {
    if (searchQuery.length === 0) {
      setFilteredProducts([]);
      setShowSearchBar(false);
    } else {
      setSearchQuery((prevQuery) => prevQuery.slice(0, -1));
      setFilteredProducts(
        products.filter((product) =>
          product.barCode.toLowerCase().includes(searchQuery.slice(0, -1).toLowerCase())
        )
      );
    }
  }

  if (selectedItemIndex !== -1 && filteredProducts[selectedItemIndex]) {
    const element = document.getElementById(`product-${selectedItemIndex}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
};


  return (
    <div className="high-light w-full bg-[#F5F5F3] dark:bg-main-dark-bg relative">
      <div className="max-w-container mx-auto">
        <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
          <div
            onClick={() => setShow(!show)}
            ref={ref}
            className="flex h-14 cursor-pointer items-center gap-2 text-primeColor"
          >
            <div className="flex text-darkColorFlop dark:text-lightColor">
              <HiOutlineMenuAlt4 className="w-5 h-5" />
              <p className="text-[14px] font-normal">{t("shop_by_category")}</p>
            </div>

            {show && (
              <motion.ul
                initial={{ y: 16, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-16 z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6 rounded"
              >
                <Link to="/men">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    {t("men")}
                  </li>
                </Link>
                <Link to="/women">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    {t("women")}
                  </li>
                </Link>
                <Link to="Kid">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    {t("kid")}
                  </li>
                </Link>
              </motion.ul>
            )}
          </div>
          <div className="relative w-full lg:w-[600px] h-[50px] text-base text-primeColor bg-white dark:bg-secondary-dark-bg flex items-center gap-2 justify-between px-6 rounded-xl">
            <input
              ref={searchInputRef}
              className="flex-1 h-full outline-none p-2 placeholder:text-[#c4c4c4] placeholder:text-[14px] bg-white dark:bg-secondary-dark-bg dark:text-white"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder={t("search")}
              onKeyDown={handleKeyDown}
            />

            {searchQuery && (
              <div>
                <div
                  className={`w-full mx-auto h-96 rounded bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}
                >
                  {searchQuery &&
                    filteredProducts.map((item, index) => (
                      <div
                        id={`product-${index}`}
                        key={`product-${index}`}
                        onClick={() =>
                          navigate(
                            `/product/${item.barCode
                              .toLowerCase()
                              .split(" ")
                              .join("")}`,
                            {
                              state: {
                                item: {
                                  _id:item.barCode,
                  barCode:item.fullName,
                  productName:item.barCode,
                  code:item.variants[0].code,
                  codes:item.codes,
                  color:item.variants[0].color,
                  colors:item.colors,
                  price:item.price,
                  brand:item.category,
                  size:item.variants[0].sizeName,
                  sizes:item.sizes,
                  desc:item.season,
                  quantity:item.variants[0].quantity,
                  img:
                  item?.variants[0]?.images?.[0] ?? flopLogoDark,
                  
                  images:
                    item?.variants[0].images
                      ? item?.variants[0].images
                      : [flopLogoDark]
                                },
                              },
                            }
                          ) &
                          setShowSearchBar(true) &
                          setSearchQuery("") &
                          setSelectedItemIndex(-1)
                        }
                        className={`max-w-[600px] h-28 rounded bg-gray-100  m-4 p-4 flex items-center gap-16 justify-start ${
                          selectedItemIndex === index ? "bg-blue-200" : ""
                        }`}
                      >
                        <img
                          className="w-24 rounded-lg hover:w-32 duration-300 shadow-xl"
                          src={item?.variants[0].images?.[0] ?? flopLogoDark}
                          alt="productImg"
                        />
                        <div className="flex flex-col gap-1">
                          <p className="font-semibold text-lg">
                            {truncate(item.barCode, { length: 20 })}
                          </p>
                          <p className="text-xs">
                            {truncate(item.desc, { length: 30 })}
                          </p>
                          <p className="text-sm">
                            Price:{" "}
                            <span className="text-primeColor font-semibold">
                              {item.price} EGP
                            </span>
                          </p>
                          <p className="text-sm">
                            <span className="text-primeColor font-semibold">
                              {item.category}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="high-light flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative focus:outline-none text-darkColorFlop dark:text-lightColor">
            <div
              onClick={() => setShowUser(!showUser)}
              ref={refShowUser}
              className="flex high-light focus:outline-none"
            >
              <FaUser />
              <FaCaretDown />
            </div>
            {showUser && (
              <motion.ul
                initial={{ y: 16, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-6 left-0 z-[110] bg-primeColor w-44 text-[#767676] h-auto p-4 pb-6 rounded"
              >
                <Link to="/signin">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    {t("login")}
                  </li>
                </Link>
                <Link onClick={() => setShowUser(false)} to="/signup">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    {t("signup")}
                  </li>
                </Link>
                <Link to="/profile">
                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    {t("profile")}
                  </li>
                </Link>
              </motion.ul>
            )}
            <Link to="/cart">
              <div className="relative">
                <FaShoppingCart className="high-light" />
                <span className="absolute top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-darkColorFlop text-white dark:bg-lightColor dark:text-darkColorFlop">
                  {productsQuantity.length > 0 ? productsQuantity.length : 0}
                </span>
              </div>
            </Link>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
