import { useTranslation } from "react-i18next";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";

import OtpInput from "otp-input-react";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser, setAuthUser } from "../../redux/flopSlice";

const PhoneAuth = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const inputRef = useRef(null);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  function onSignup(e) {
    e.preventDefault();

    if (!ph || ph.length > 12 || ph.length < 12) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sended successfully!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  }

  function onOTPVerify(e) {
    e.preventDefault();
    if (!otp || otp.length !== 6) {
      toast.error("Please enter a valid OTP.");
      return;
    }
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        setUser(res.user);
        const token = res.user.accessToken;
        dispatch(
          loginUser({
            uid: res.user.uid,
            email: res.user.phoneNumber,
          }),
          dispatch(setAuthUser())
        );
        localStorage.setItem("authToken", token);
        localStorage.setItem("email", res.user.phoneNumber);
        localStorage.setItem("uid", res.user.uid);
        setLoading(false);
        toast.success("User created successfully!");

        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const userFromStorage = localStorage.getItem("mobile");
  useEffect(() => {
    if (userFromStorage) setUser(userFromStorage);
  }, [userFromStorage]);

  const handleOtpKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      onOTPVerify(e);
    }
  };

  return (
    <>
      <div className=" flex items-center justify-center">
        <div>
          <Toaster toastOptions={{ duration: 4000 }} />
          <div id="recaptcha-container"></div>
          {user ? (
            <h2 className="text-center text-flopdarkColor font-medium text-2xl">
              👍Login Success With {userFromStorage}
            </h2>
          ) : (
            <div className=" flex flex-col gap-4 rounded-lg p-2">
              {showOTP ? (
                <>
                  <div className="bg-flopdarkColor text-white  mx-auto p-4 rounded-full">
                    <BsFillShieldLockFill size={30} />
                  </div>
                  <label
                    htmlFor=""
                    className="font-bold text-xl text-flopdarkColor text-center"
                  >
                    Enter your OTP
                  </label>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    autoFocus
                    onKeyDown={handleOtpKeyPress}
                    className="opt-container bg-flopdarkColor/25 border-[1px] border-flopdarkColor rounded-lg p-1"
                  ></OtpInput>
                  <button
                    onClick={onOTPVerify}
                    className="bg-flopdarkColor  flex gap-1 items-center justify-center py-2.5 text-white rounded"
                  >
                    {loading && (
                      <CgSpinner size={20} className="mt-1 animate-spin" />
                    )}
                    <span>Verify OTP</span>
                  </button>
                </>
              ) : (
                <>
                  <div className="bg-flopdarkColor text-white w-fit mx-auto p-4 rounded-full">
                    <BsTelephoneFill size={30} />
                  </div>
                  <label
                    htmlFor=""
                    className="font-bold text-xl text-flopdarkColor text-center"
                  >
                    {t("verify_number")}
                  </label>
                  <PhoneInput
                    country={"eg"}
                    value={ph}
                    onChange={setPh}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        onSignup(e)
                          .then(() => {
                            inputRef.current.blur();
                            toast.success("Submitted successfully!");
                          })
                          .catch((error) => {
                            inputRef.current.focus();
                            toast.error(error.message);
                          })
                          .finally(() => {
                            setLoading(false);
                          });
                      }
                    }}
                    countryCodeEditable={false}
                  />
                  <button
                    onClick={onSignup}
                    className="bg-flopdarkColor  flex gap-1 items-center justify-center py-2 text-white rounded hover:bg-flopdarkColor/95"
                  >
                    {loading && (
                      <CgSpinner size={20} className="mt-1 animate-spin" />
                    )}
                    <span>Send code via SMS</span>
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PhoneAuth;
