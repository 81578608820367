export const themeColors = [
  {
    color: "#104d9c",
    name: "Flop-Color",
  },
  {
    color: "#6D6D6D",
    name: "gray-theme",
  },
  {
    name: "blue-theme",
    color: "#1A97F5",
  },
  {
    name: "green-theme",
    color: "#000",
  },
  {
    name: "purple-theme",
    color: "#7352FF",
  },
  {
    name: "red-theme",
    color: "#FF5C8E",
  },
  {
    name: "red-theme-dark",
    color: "#EF4444",
  },
 
];
