import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ar_translation from "./assets/locales/ar_translation.json";
import en_translation from "./assets/locales/en_translation.json";

const resources = {
  en: {
    translation: en_translation,
  },
  ar: {
    translation: ar_translation,
  },
};

i18n

  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    preload: true, 
    // lng: "en", 
    resources,
    fallbackLng: "en",
    detection: {
      order: ["localStorage", "cookie", "htmlTag"],
      caches: ["cookie","localStorage"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
