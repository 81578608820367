import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import { MdOutlineCancel } from "react-icons/md";
import { BsCheck } from "react-icons/bs";
import { themeColors } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { flopLogoDark, flopLogoLight } from "../assets/images";





const ThemeSettings = () => {
  const {  t } = useTranslation()
  const fontChange = localStorage.getItem("i18nextLng");


  const { setColor, setMode, currentMode, currentColor, setThemeSettings } =
    useStateContext();
  const [emailExists, setEmailExists] = useState(localStorage.getItem("email"));

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div className={`float-right h-screen dark:text-gray-200  bg-main-bg/90 dark:bg-main-dark-bg/90 w-400 ${fontChange === "ar" ? "font-bodyFontAr" : "font-bodyFont"}`}>
        <div className="flex justify-between items-center p-4 ml-4">  
          <p className="font-semibold text-lg">{t("settings")}</p>
          <button
            type="button"
            onClick={() => setThemeSettings(false)}
            style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <MdOutlineCancel />
          </button>
        </div>
        
         
        <div className="flex-col border-t-1 border-color p-4 ml-4 select-none">
          <p className="font-semibold text-xl ">{t("theme_colors")}</p>

          <div className="mt-4">
            <input
              type="radio"
              id="light"
              name="theme"
              value="Light"
              className="cursor-pointer"
              onChange={setMode}
              checked={currentMode === "Light"}
              onClick={() => setThemeSettings(false)}
            />
            <label htmlFor="light" className="ml-2 text-md cursor-pointer">
             {t("theme_light")}
            </label>
          </div>
          <div className="mt-2">
            <input
              type="radio"
              id="dark"
              name="theme"
              value="Dark"
              onChange={setMode}
              className="cursor-pointer"
              checked={currentMode === "Dark"}
              onClick={() => setThemeSettings(false)}
            />
            <label htmlFor="dark" className="ml-2 text-md cursor-pointer">
            {t("theme_dark")}
            </label>
          </div>
        </div>
        <div className="p-4 border-t-1 border-color ml-4">
          <p className="font-semibold text-xl ">{t("system_colors")}</p>
          <div className="flex flex-wrap gap-3">
            {themeColors.map((item, index) => (
              <div
                className="high-light relative mt-2 cursor-pointer flex  gap-5 items-center"
                key={index}
              >
                <button
                  type="button"
                  className="h-10 w-10 rounded-full cursor-pointer"
                  style={{ backgroundColor: item.color }}
                  onClick={() => {
                    setColor(item.color);
                    setThemeSettings(false);
                  }}
                >
                  <BsCheck
                    className={`ml-2 text-2xl text-white ${
                      item.color === currentColor ? "block" : "hidden"
                    }`}
                  />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="bottom-0">
          {emailExists && (
            <div className=" flex flex-col justify-around items-center gap-4 bg-flopdarkColor/10 rounded p-2 m-10 ">
              <div className="w-32 flex gap-6 items-center justify-around ">
                {/* <FaUser/> */}
                <div className="w-14 h-14">
                  <img
                    className="w-12 h-12 rounded-full object-cover "
                    src={
                      localStorage.getItem(
                        `profileImageUrl_${localStorage.getItem("uid")} `
                      ) || currentMode === "Dark"
                        ? flopLogoLight
                        : flopLogoDark
                    }
                    alt="Profile picture"
                  />
                </div>
              </div>
              <h1>{emailExists}</h1>
              <a href="/profile">{t("my_profile")}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThemeSettings;
