import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/flopSlice";
import { useStateContext } from "../../../contexts/ContextProvider";

const ProductInfo = ({ productInfo }) => {
  const { currentMode } = useStateContext();
  const dispatch = useDispatch();

  // Set initial color, size, and code based on the first available options
  const [selectedColor, setSelectedColor] = useState("");
  console.log("🚀 ~ ProductInfo ~ selectedColor:", selectedColor);
  const [selectedSize, setSelectedSize] = useState("");
  console.log("🚀 ~ ProductInfo ~ selectedSize:", selectedSize);
  const [selectedCode, setSelectedCode] = useState("");
  console.log("🚀 ~ ProductInfo ~ selectedCode:", selectedCode);

  useEffect(() => {
    if (productInfo?.colors && productInfo?.sizes && productInfo?.codes) {
      // Set initial values for color, size, and code
      const initialColor = productInfo.colors[0] || "";
      const initialSize = productInfo.sizes[0] || "";
      const initialIndex = productInfo.colors.findIndex(
        (color, i) =>
          color === initialColor && productInfo.sizes[i] === initialSize
      );

      setSelectedColor(initialColor);
      setSelectedSize(initialSize);

      if (initialIndex !== -1) {
        setSelectedCode(productInfo.codes[initialIndex]);
      }
    }
  }, [productInfo]);

  useEffect(() => {
    if (productInfo?.colors && productInfo?.sizes && productInfo?.codes) {
      // Find the index of the selected color and size combination
      const index = productInfo.colors.findIndex(
        (color, i) =>
          color === selectedColor && productInfo.sizes[i] === selectedSize
      );

      // Update the code based on the selected color and size
      if (index !== -1) {
        setSelectedCode(productInfo.codes[index]);
      }
    }
  }, [selectedColor, selectedSize, productInfo]);

  const handleColorChange = (e) => {
    setSelectedColor(e.target.value);
  };

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };

  return (
    <div className={currentMode === "Dark" ? "dark" : "light"}>
      <div className="flex flex-col gap-5 text-main-dark-bg dark:text-lightColor">
        <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
        <p className="text-xl font-semibold">EGY {productInfo.price}</p>
        <p className="text-base">
          Code: {selectedCode ? selectedCode : productInfo.code}
        </p>
        <p className="text-base">Brand: {productInfo.brand}</p>
        <p className="text-base">Description: {productInfo.desc}</p>
        <p className="text-base">
          Size: {selectedSize ? selectedSize : productInfo.size}
        </p>
        <p className="text-base">
          Color: {selectedColor ? selectedColor : productInfo.color}
        </p>

        <div className="w-full flex gap-4 text-lightColor dark:text-main-dark-bg">
          <div className="flex flex-col gap-2 w-full">
            <label
              htmlFor="colorSelector"
              className="font-medium text-main-dark-bg dark:text-lightColor"
            >
              Select Color:
            </label>
            <select
              id="colorSelector"
              className="w-full py-2 px-3 border-2 border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500  text-black bg-light-gray dark:bg-secondary-dark-bg/20 dark:text-white hover:drop-shadow-lg shadow-lg transition-colors"
              value={selectedColor}
              onChange={handleColorChange}
            >
              {productInfo?.colors?.map((color, index) => (
                <option key={index} value={color}>
                  {color}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label
              htmlFor="sizeSelector"
              className="font-medium text-main-dark-bg dark:text-lightColor"
            >
              Select Size:
            </label>
            <select
              id="sizeSelector"
              className="w-full py-2 px-3 border-2 border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500  text-black  bg-light-gray dark:bg-secondary-dark-bg/20 dark:text-white hover:drop-shadow-lg shadow-lg transition-colors"
              value={selectedSize}
              onChange={handleSizeChange}
            >
              {productInfo?.sizes?.map((size, index) => (
                <option key={index} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="table-auto w-full text-lg text-darkColorFlop dark:text-white bg-light-gray dark:bg-secondary-dark-bg/20 hover:drop-shadow-lg shadow-lg rounded-lg">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-r text-center">
                  Color
                </th>
                <th className="py-2 px-4 border-b border-r text-center">
                  Size
                </th>
                <th className="py-2 px-4 border-b text-center">Code</th>
              </tr>
            </thead>
            <tbody>
              {productInfo?.colors?.map((color, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-r text-center">{color}</td>
                  <td className="py-2 px-4 border-r text-center uppercase">
                    {productInfo?.sizes[index]}
                  </td>
                  <td className="py-2 px-4 text-center">
                    {productInfo?.codes[index]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button
          onClick={() =>
            dispatch(
              addToCart({
                _id: productInfo._id,
                name: productInfo.productName,
                quantity: 1,
                images: productInfo.images[0],
                price: productInfo.price,
                color: selectedColor,
                size: selectedSize,
                code: selectedCode,
              })
            )
          }
          className="w-full py-4 duration-300 text-lg text-darkColorFlop dark:text-white rounded-full hover:opacity-95 hover:drop-shadow-lg shadow-lg bg-light-gray dark:bg-secondary-dark-bg/20"
        >
          Add to Cart
        </button>

        <p className="font-normal text-sm pb-2">
          <span className="text-base font-medium">Categories:</span> Spring
          collection, Streetwear, Women Tags: featured
        </p>
      </div>
    </div>
  );
};

export default ProductInfo;
