import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Header from '../../components/home/Header/Header'
import Footer from '../../components/home/Footer/Footer'
import FooterBottom from "../../components/home/Footer/FooterBottom";

const Privacy = () => {
 
  
  return (
    <div className="relative max-w-container h-[100vh] mx-auto px-4">
    <div className="absolute w-full">
    <Header />  
    </div>
      <div className="relative top-11">
        <Breadcrumbs title="Privacy Policy"  />
      <div className="pb-10">
      <div className="bg-gray-100 dark:bg-main-dark-bg-lighter text-gray-700 dark:text-white p-4 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
      <p >
        This Privacy Policy describes how Flop Stores collects, uses, and protects your personal information when you visit our website <span className="text-blue-500"><a href="https://flopstores.com "> Flop Stores </a> </span> and the choices you have associated with that data.
      </p>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Information We Collect</h3>
        <p >
          We may collect personal information such as your name, email address, phone number, billing and shipping address, and browsing history on our Website. This information is collected when you create an account, place an order, subscribe to our newsletter, or contact us.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Use of Information</h3>
        <p >
          We use your information to process your orders, send you promotional emails (with your consent), personalize your browsing experience, improve our services, and comply with legal obligations.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Sharing of Information</h3>
        <p >
          We will not sell or share your personal information with third parties without your consent. However, we may share your information with service providers who assist us in operating the Website, fulfilling orders, and sending communications. These service providers are obligated to protect your information.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Data Security</h3>
        <p >
          We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. However, no website can guarantee complete security.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Your Choices</h3>
        <p >
          You can opt out of receiving promotional emails by clicking the "unsubscribe" link at the bottom of our emails. You can also access and update your personal information by logging into your account.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Cookies</h3>
        <p >
          We use cookies to track your activity on the Website and improve your experience. You can choose to disable cookies in your browser settings, but this may limit your ability to use certain features of the Website.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Links to Other Websites</h3>
        <p >
          Our Website may contain links to other websites. We are not responsible for the privacy practices of these websites. We encourage you to read the privacy policies of any website you visit.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Children's Privacy</h3>
        <p >
          Our Website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-lg font-medium mb-2">Changes to this Policy</h3>
        <p >
          We may update this Privacy Policy from time to time. We will post any changes on the Website. We encourage you to review this Policy periodically.
        </p>
      </section>
    </div>
      </div>
      <Footer />
      <FooterBottom />
      </div>
    </div>
  );
};

export default Privacy;
