import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const ConnectionStatusToast = () => {
  const [isConnected, setIsConnected] = useState(window.navigator.connection);

  useEffect(() => {
    const handleOnline = () => {
      setIsConnected(true);
      toast.success("You are back online!");
    };

    const handleOffline = () => {
      setIsConnected(false);
      toast.error("You are offline.");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
};

export default ConnectionStatusToast;
