import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import {t} from "i18next";

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4 text-black dark:text-white">
      <Breadcrumbs title="About" prevLocation={prevLocation} />
      <div className="pb-10">
      
        <h1 className="max-w-[600px] text-base  mb-2">
          <span className="text-flopdarkColor font-semibold text-lg">FLOP Stores</span>{" "}
          Is The Best Clothes & Footwear Store in Alexandria

        </h1>
        <section>
          <h1 className="text-2xl font-semibold mb-4 text-flopdarkColor">Our Mission</h1>
          <p>
          {t("mission")}
          </p>
          
        </section>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
