import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";

const FooterListTitle = ({ title }) => {
  

  return (
    
      <h3 className="text-xl font-bodyFont font-semibold mb-2 text-black dark:text-lightColor">
        {title}
      </h3>
    
  );
};

export default FooterListTitle;
