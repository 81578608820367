import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "./redux/productSlice"; // Assuming productsSlice is your slice file
import Product from "./components/home/Products/Product";
import Heading from "./components/home/Products/Heading";
import { flopLogoDark } from "./assets/images";
import "aos/dist/aos.css";

const ProductsListRedux = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.productsReducer.items);
  const status = useSelector((state) => state.productsReducer.status);
  const error = useSelector((state) => state.productsReducer.error);
  

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchProducts()).unwrap().catch((err) => {
        if (err !== "Data is still fresh") {
          console.error("Failed to fetch products:", err);
        }
      });
    }
  }, [dispatch, status]);

  return (
    <div>
  <Heading heading="New Arrivals" />
  {status === "loading" && <p>Loading products...</p>}
  {status === "succeeded" && products.length > 0 && (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
      {products &&
        products.map((product, index) => (
          <div
            key={index}
            className="pt-2 pb-2"
            data-aos="flip-left"
            data-aos-easing="ease-out-quad"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <Product
              _id={product.barCode}
              barCode={product.fullName}
              productName={product.barCode}
              code={product.variants[0].code}
              codes={product.codes}
              color={product.variants[0].color}
              colors={product.colors}
              price={product.price}
              brand={product.category}
              badge={product.badge}
              size={product.variants[0].sizeName}
              sizes={product.sizes}
              desc={product.season}
              quantity={product.variants[0].quantity}
              img={product?.variants[0]?.imagesUrls?.[0] ?? flopLogoDark}
              images={
                product?.variants[0].imagesUrls
                  ? product?.variants[0].imagesUrls
                  : [flopLogoDark]
              }
            />
          </div>
        ))}
    </div>
  


      )}
      {status === "succeeded" && products.length === 0 && (
        <p>No products found.</p>
      )}
      {status === "failed" && <p>Error: {error}</p>}
    </div>
  );
};

export default ProductsListRedux;
