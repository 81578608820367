import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import "./index.css";
import i18n from './i18n';
import App from "./App";
import { UserProvider } from './pages/Home/UserContext';
import { Toaster } from 'react-hot-toast';
import { ContextProvider } from './contexts/ContextProvider';
// import { I18nextProvider } from "react-i18next";
// import { LanguageProvider } from './LanguageContext';




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ContextProvider>
  <UserProvider>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <Toaster position='top-center' reverseOrder={false} />
    
    {/* <I18nextProvider i18n={i18n}> */}
    {/* <LanguageProvider> */}
      <App />
      {/* </LanguageProvider> */}
      {/* </I18nextProvider> */}
      
    </PersistGate>
  </Provider>
  </UserProvider>
  </ContextProvider>
 
  
);
