import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill } from "react-icons/bs";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Assuming you're using Font Awesome icons
import Header from "../../components/home/Header/Header";

import FullPageLoader from "../../components/designLayouts/FullPageLoader.jsx";

import { toast } from "react-hot-toast";

import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";


const SignUp = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

 
  const [checked, setChecked] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [formData, setFormData] = useState({
    clientName: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    city: "",
    country: "",
  });

  const { clientName, email, phone, password, address, city, country } =
    formData;

  const dispatch = useDispatch();

  const areAllFieldsFilled = () => {
    return (
      clientName.trim() !== "" &&
      email.trim() !== "" &&
      password.trim() !== "" &&
      phone.trim() !== "" &&
      address.trim() !== "" &&
      city.trim() !== "" &&
      country.trim() !== ""
    );
  };

  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  const PhoneValidation = (phone) => {
    return phone.length === 11;
  };

  const handleProceedSignup = () => {
    if (!areAllFieldsFilled()) {
      toast.error("Please complete the form");
    } else if (!EmailValidation(email)) {
      toast.error("Please enter a valid email address");
    } else if (!PhoneValidation(phone)) {
      toast.error("Please enter a valid Phone number");
    } else {
      onSubmit();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      clientName &&
      email &&
      EmailValidation(email) &&
      phone &&
      PhoneValidation(phone) &&
      password &&
      password.length >= 6 &&
      address &&
      city &&
      country
    ) {
      const storeUserDataInFirebase = async (formData) => {
        const db = getFirestore();

        try {
          const docRef = await addDoc(collection(db, "usersdetails"), formData);

          toast.success("Document written with ID: ", docRef.id);
        } catch (error) {
          toast.error("Error adding document: ", error);
        }
      };

      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        storeUserDataInFirebase(formData);
        setIsLoading(true);
        setTimeout(() => {
          navigate("/signin");
        }, 3000);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("Please fill all fields correctly");
    }
  };

  useEffect(() => {
    if (areAllFieldsFilled()) {
      toast.success("Now You can Signup");
    }
  }, [formData]);

  return (
    <>
      {isLoading && (
        <FullPageLoader bg="rgba(255, 255, 255, 0.8)"></FullPageLoader>
      )}
      <Header />

      <div className="w-full h-screen flex items-center justify-start">
        <div className="w-1/2 hidden lgl:inline-flex h-full text-black dark:text-white">
          <div className="w-[450px] h-full   px-10 flex flex-col gap-6 justify-around ">
            <div className="flex flex-col gap-1 -mt-1">
              <h1 className=" text-xl font-medium">Get started for free</h1>
              <p className="text-base">Create your account to access more</p>
            </div>
            <div className="w-[300px] flex items-start gap-3">
              <span className="text-green-500 mt-1">
                <BsCheckCircleFill />
              </span>
              <p className="text-base ">
                <span className=" font-semibold ">
                  Get started fast with FLOP
                </span>
                <br />
                Sign up for FLOP in just a few clicks and start shopping
                immediately
              </p>
            </div>
            <div className="w-[300px] flex items-start gap-3">
              <span className="text-green-500 mt-1">
                <BsCheckCircleFill />
              </span>
              <p className="text-base ">
                <span className=" font-semibold ">
                  Access all FLOP Products
                </span>
                <br />
                Discover products you'll love with FLOP's smart suggestions
                based on your preferences.
              </p>
            </div>
            <div className="w-[300px] flex items-start gap-3">
              <span className="text-green-500 mt-1">
                <BsCheckCircleFill />
              </span>
              <p className="text-base ">
                <span className=" font-semibold ">
                  Trusted by online Shoppers
                </span>
                <br />
                Secure online shopping you can trust.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
          {successMsg ? (
            <div className="w-[500px]">
              <p className="w-full px-4 py-10 text-green-500 font-medium ">
                {successMsg}
              </p>
              <Link to="/signin">
                <button
                  className="w-full h-10 bg-primeColor rounded-md text-gray-200 text-base  font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300"
                >
                  Sign in
                </button>
              </Link>
            </div>
          ) : (
            <form className="w-full lgl:w-[500px] h-screen flex items-center justify-center text-flopdarkColor">
              <div className="px-6 py-4 w-full h-[96%] flex flex-col justify-start">
                <h1 className="underline underline-offset-4 decoration-[1px] font-semibold text-2xl mdl:text-3xl mb-4">
                  Create your account
                </h1>
                <div className="flex flex-col gap-3 ">
                  <div className="flex flex-col gap-.5 text-flopdarkColor">
                    <p className=" text-base font-semibold ">Full Name</p>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, clientName: e.target.value })
                      }
                      value={clientName}
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="text"
                      placeholder="eg. John Doe"
                    />
                  </div>

                  <div className="flex flex-col gap-.5">
                    <p className=" text-base font-semibold ">Email</p>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      value={email}
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="email"
                      placeholder="john@workemail.com"
                    />
                  </div>

                  <div className="flex flex-col gap-.5">
                    <p className=" text-base font-semibold text-flopdarkColor">
                      Password
                    </p>
                    <div className="relative">
                      <input
                        onChange={(e) =>
                          setFormData({ ...formData, password: e.target.value })
                        }
                        value={password}
                        className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                        type={showPassword ? "text" : "password"}
                        placeholder="Create password"
                      />
                      <span
                        className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col gap-.5">
                    <p className=" text-base font-semibold ">Phone Number</p>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                      value={phone}
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="tel"
                      placeholder="01223456789"
                    />
                  </div>

                  <div className="flex flex-col gap-.5">
                    <p className=" text-base font-semibold text-flopdarkColor">
                      Address
                    </p>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, address: e.target.value })
                      }
                      value={address}
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="text"
                      placeholder="road-001, house-115, example area"
                    />
                  </div>

                  <div className="flex flex-col gap-.5">
                    <p className=" text-base font-semibold text-flopdarkColor">
                      City
                    </p>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, city: e.target.value })
                      }
                      value={city}
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="text"
                      placeholder="Your city"
                    />
                  </div>

                  <div className="flex flex-col gap-.5">
                    <p className=" text-base font-semibold text-flopdarkColor">
                      Country
                    </p>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, country: e.target.value })
                      }
                      value={country}
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="text"
                      placeholder="Your country"
                    />
                  </div>

                  <div className="flex items-start mdl:items-center gap-2">
                    <input
                      onChange={() => setChecked(!checked)}
                      className="w-4 h-4 mt-1 mdl:mt-0 cursor-pointer"
                      type="checkbox"
                    />
                    <p className="text-sm text-lightText">
                      I agree to the FLOP{" "}
                      <span className="text-flopdarkColor">
                        Terms of Service{" "}
                      </span>
                      and{" "}
                      <span className="text-flopdarkColor">Privacy Policy</span>
                      .
                    </p>
                  </div>
                  <button
                    onClick={onSubmit}
                    disabled={!checked}
                    className={`${
                      checked
                        ? "bg-flopdarkColor hover:bg-black hover:text-white cursor-pointer"
                        : "bg-gray-500 hover:bg-gray-500 hover:text-gray-200 cursor-none"
                    } w-full text-gray-200 text-base font-medium h-10 rounded-md hover:text-white duration-300`}
                  >
                    Create Account
                  </button>
                  <div className="flex justify-center items-center font-bold my-2">
                    OR
                  </div>

                  <p className="text-sm text-center  font-medium">
                    Don't have an Account?{" "}
                    <Link to="/signin">
                      <span className="hover:text-blue-600 duration-300">
                        {t("login")}
                      </span>
                    </Link>
                  </p>
                </div>
                <div className="flex items-center justify-between mt-10">
                  <Link to={"/"}>
                    <p className="text-sm  font-semibold   cursor-pointer ">
                      © FLOP
                    </p>
                  </Link>
                  <Link to={"/refund-and-exchange"}>
                    <p className="text-sm  font-semibold   cursor-pointer ">
                      Terms
                    </p>
                  </Link>
                  <Link to={"/privacy-policy"}>
                    <p className="text-sm  font-semibold   cursor-pointer ">
                      Privacy
                    </p>
                  </Link>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default SignUp;
