import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

// Create a single supabase client for interacting with your database
const supabase = createClient('https://osuuggvwikirmwtaycon.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9zdXVnZ3Z3aWtpcm13dGF5Y29uIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjA2MzU2ODMsImV4cCI6MjAzNjIxMTY4M30.w3Inj_AxZw6FC3tQpZhI6CkuMrGNwI_UPk4ot_-ptTc');

const Chat = () => {
  const [posts, setPosts] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      const { data } = await supabase
        .from('posts')
        .select('*')
        .order('created_at', { ascending: true });
      setPosts(data);
    };

    fetchPosts();

    const subscription = supabase
      .channel('posts')
      .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'posts' }, (payload) => {
        setPosts((prevPosts) => [...prevPosts, payload.new]);
      })
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data, error } = await supabase
      .from('posts') // Replace with your table name
      .insert([{ name: newMessage, description: newMessage }]);

    if (error) {
      console.error(error);
    } else {
      setNewMessage('');
    }
  };

  const handleDelete = async (id) => {
    const { error } = await supabase
      .from('posts') // Replace with your table name
      .delete()
      .match({ id });

    if (error) {
      console.error(error);
    } else {
      setPosts(posts.filter(post => post.id !== id));
    }
  };

  return (
    <div className="fixed bottom-5 left-5 flex flex-col items-end z-[1001] rounded-lg ">
      <button
        onClick={() => setIsChatOpen(!isChatOpen)}
        className="w-12 h-12 bg-flopdarkColor text-white rounded-full flex items-center justify-center  focus:outline-none shadow-sm drop-shadow-sm dark:shadow-white/30"
      >
        {isChatOpen ? 'X' : '💬'}
      </button>
      {isChatOpen && (
        <div className="bg-main-bg/90 dark:bg-main-dark-bg/90  border p-4 max-w-md w-full shadow-sm drop-shadow-sm dark:shadow-white/30 rounded-lg mt-2">
          <div className="mb-4 max-h-60 overflow-y-auto"
         
          >
          
            {posts.map((post) => (
              <div key={post.id} className="p-2 m-2  border rounded flex flex-col justify-between items-center">
                <span className='dark:text-white break-all m-1'>{post.name}</span>
                <button onClick={() => handleDelete(post.id)} className="bg-red-500 text-white p-1 rounded hover:animate-bounce2">Delete</button>
              </div>
            ))}
          </div>
          <form onSubmit={handleSubmit} className="flex">
            <input 
              type="text" 
              value={newMessage} 
              onChange={(e) => setNewMessage(e.target.value)} 
              className="border p-2 flex-grow rounded-l" 
              placeholder="Type your message..."
            />
            <button type="submit" className="bg-blue-500 text-white p-2 rounded-r">Send</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Chat;
