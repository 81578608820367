import React from "react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import Badge from "./Badge";
import BadgeOrg from "./BadgeOrg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/flopSlice";
import { useStateContext } from "../../../contexts/ContextProvider";

const Product = (props) => {
  const { currentMode } = useStateContext();
  const dispatch = useDispatch();
  const _id = props._id;
  // console.log("🚀 ~ Product ~ _id:", _id)
  const idString = (_id) => {
    return String(_id).toLowerCase().split(" ").join("");
  };
  const rootId = idString(_id);
  // console.log("🚀 ~ Product ~ rootId:", rootId)

  const navigate = useNavigate();
  const productItem = props;
  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: productItem,
      },
    });
  };
  const stock = props.quantity ;
  // console.log("🚀 ~ Product ~ stock:", stock)
  

  return (
    
      <div className="w-full relative group shadow-sm">
        <div className="rounded-t-xl max-w-80 max-h-80 relative overflow-y-hidden  ">
          <div className="cursor-pointer " onClick={handleProductDetails}>
            <Image
              className="w-full h-full bg-white/5 drop-shadow-md"
              imgSrc={props.img}
            />
          </div>

          <div className="absolute top-1 left-1">
            {props.badge && <Badge text="New" />}
          </div>
          {/* <div className="absolute top-6 right-8">
            {props.badgeorg && <BadgeOrg text="Original" />}
          </div> */}
          <div className="w-full h-20 absolute rounded-t-lg bg-flopdarkColor bg-opacity-80 -bottom-[45px] group-hover:bottom-0 duration-700">
            <ul className="w-full h-full flex flex-col items-end justify-center gap-2  px-2 border-l border-r">
              <li
                onClick={() =>
                  props.quantity !== 0 &&
                  dispatch(
                    addToCart({
                      _id: props._id,
                      name: props.productName,
                      quantity: 1,
                      images: props.images[0],
                      price: props.price,
                      color: props.color,
                      size: props.size,
                      code: props.code,
                    })
                  )
                }
                className={`text-white shadow-lg text-sm font-normal border-b-[1px] border-b-gray-200 flex items-center justify-center gap-2 pb-1 duration-300 w-full ${
                  props.quantity === 0
                    ? "cursor-not-allowed opacity-50"
                    : "hover:text-blue-400 hover:border-b-primeColor hover:cursor-pointer"
                }`}
              >
                Add to Cart
                <span>
                  <FaShoppingCart
                    className={`animate-bounce2 duration-300 w-4 ${
                      props.quantity === 0 ? "opacity-50" : ""
                    }`}
                  />
                </span>
              </li>

              <li
                onClick={handleProductDetails}
                className="text-white shadow-lg hover:text-blue-400 text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-center gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
              >
                View Details
                <span className="text-lg">
                  <MdOutlineLabelImportant />
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="max-w-80 py-6 rounded-b-xl flex flex-col gap-1 border-[1px] border-t-0 px-4">
          <div className="flex items-center justify-between  dark:text-lightColor ">
            <h2 className="text-lg  font-bold">{props.productName}</h2>
            <p className=" text-[14px]">EGY {props.price}</p>
          </div>
          <div className=" dark:text-lightColor ">
            <div>
              <p className=" text-[14px]">{props.desc}</p>
            </div>
            <div>
              <p className=" text-[14px]">{props.color}</p>
            </div>
            <div>
              <p className=" text-[14px]">{props.brand}</p>
            </div>
            <div>
              <p className=" text-[14px]">{props.code}</p>
            </div>
            <div>
              <p className=" text-[14px]">{props.rate}</p>
            </div>
            <div>
              <p className=" text-[14px]">{props.size}</p>
            </div>
            <div>
              <p className=" text-[14px]">{props.id}</p>
            </div>
            <div>
              <p className=" text-[14px]">{props.barCode}</p>
            </div>
            <div>
              <p className=" text-[14px]">{props.variants}</p>
            </div>
            {/* <div>
              <p className=" text-[14px]">{props.badge}</p>
            </div> */}
            
            {/* {stock && (
              <div>
                <p className=" text-[14px] py-2">
                  Remains{" "}
                  <span className="bg-flopdarkColor p-1 text-white rounded">
                    {" "}
                    {props?.quantity}
                  </span>{" "}
                  items left in stock
                </p>
              </div>
            )}
            {stock < 0 && (
              <p className="bg-red-600 text-white rounded p-2 m-2 flex justify-center">
                This product is out of the stock.
              </p>
            )} */}
            
          </div>
        </div>
      </div>
    
  );
};

export default Product;
