import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";

import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDjIveATgf2phzjaXbn66WlKhpB-Zrl9m8",
  authDomain: "flop-stores.firebaseapp.com",
  projectId: "flop-stores",
  storageBucket: "flop-stores.appspot.com",
  messagingSenderId: "346902016162",
  appId: "1:346902016162:web:69aef6d8608e09f9cfa7fb",
  measurementId: "G-V08RLZ9Y6R",
  appVerificationDisabledForTesting: true,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
