import React, { useState, useEffect, useRef } from "react";
import { TiZoomIn, TiZoomOut } from "react-icons/ti";
import gsap from "gsap";
import { TbZoomCancel } from "react-icons/tb";

const ZoomMessage = () => {
  const [zoomInfo, setZoomInfo] = useState(false);
  const consentContainerRef = useRef(null);


  useEffect(() => {
    const tl = gsap.timeline();

    setTimeout(() => {
      tl.fromTo(
        consentContainerRef.current,
        { y: 100, duration: 6, scale: 2, ease: "power2.out", opacity: 0 },
        { y: 0, duration: 2, ease: "elastic.out", scale: 1, opacity: 1 }
      );
    }, 3000);

    return () => tl.kill();
  }, []);

  useEffect(() => {
    const zoomConsent = localStorage.getItem("productZoomDismissed");
    if (!zoomConsent) {
      setTimeout(() => {
        setZoomInfo(true);
      }, 3000);
     
    }
  }, []);

  const GotIt = () => {
    const tl = gsap.timeline();
    tl.to(consentContainerRef.current, {
      duration: 1.5, 
      scale: 0.2, 
      y: -1000, 
      opacity: 1,
      ease: "power2.inOut", 
      onComplete: () => setZoomInfo(false), 
    });

    tl.play(); // Play the timeline
    localStorage.setItem("productZoomDismissed", true);
    return () => tl.kill();
  };
    
    
 

  return (
    <>
    <div
        ref={consentContainerRef}
        className="fixed bottom-0 left-0 right-0 text-white p-4 flex flex-col items-center z-50"

      >
      {zoomInfo && (
        <div className="fixed bottom-0 left-0 right-0 text-white p-4 flex flex-col  items-center  z-50">
          <div className="group  bg-black bg-opacity-90 shadow-lg transition duration-300 hover:bg-opacity-100 hover:scale-x-110 hover:scale-y-110 hover:shadow-2xl rounded-lg p-4 w-auto">
            <div className="flex-1 items-center justify-center content-center">
              <div className="flex justify-center items-center mt-4">
                <span className="text-white font-bold">
                  Click Image to zoom in/out{" "}
                  <span className="text-[1rem] flex justify-center items-center">
                    <TiZoomIn /> To Zoom In{" "}
                  </span>{" "}
                  <span className="text-[1rem] flex justify-center items-center">
                    <TiZoomOut /> To Zoom Out{" "}
                  </span>{" "}
                  
                  <span className="text-[1rem] flex justify-center items-center">
                    <TbZoomCancel /> To Reset{" "}
                  </span>
                  
                </span>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <button
                onClick={GotIt}
                className="bg-green-500 hover:bg-green-600 text-white font-semibold px-4 py-2 rounded focus:outline-none mr-2"
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </>
  );
};

export default ZoomMessage;
