import React, { useState } from "react";
import { ImPlus } from "react-icons/im";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

const Category = () => {
  const [showSubCatOne, setShowSubCatOne] = useState(true);
  const navigate = useNavigate(); 

  const items = [
    {
      _id: 990,
      title: "Men",
      url: "men"
    },
    {
      _id: 991,
      title: "Women",
      url: "women"
    },
    {
      _id: 992,
      title: "Kid",
      url: "kid"
    }
    
  ];

  const handleClick = (url) => {
    navigate(`/${url.toLowerCase()}`); 
  };

  return (
    <div>
      <div className="cursor-pointer" onClick={() => setShowSubCatOne(!showSubCatOne)}>
        <NavTitle title="Shop by Category" icons={true} />
      </div>
      {showSubCatOne && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="flex flex-col gap-4 text-sm lg:text-base text-darkColorFlop dark:text-white">
            {items.map(({ _id, title }) => (
              <li
                key={_id}
                className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center justify-between"
                onClick={() => handleClick(title)} // Bind handleClick to title
              >
                <button>{title}</button>
                
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default Category;
