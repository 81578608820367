import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { resetCart } from "../../redux/flopSlice";
import { emptyCart } from "../../assets/images/index";
import ItemCard from "./ItemCard";
import { useStateContext } from "../../contexts/ContextProvider";
import PayMob from "./Paymob";

const Cart = () => {
  const { currentColor } = useStateContext();

  function financial(x) {
    return Number.parseFloat(x).toFixed(2);
  }
  const dispatch = useDispatch();
  const products = useSelector((state) => state.flopReducer.products);
  const [shippingCharge, setShippingCharge] = useState("");
  const [totalAmt, setTotalAmt] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [discountAmt, setDiscountAmt] = useState(0);
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const originalTotalAmt = totalAmt;

  const calculateTotal = () => {
    const subTotal = totalAmt; 
    return isDiscountApplied ? subTotal - discountAmt : subTotal;
  };

  useEffect(() => {
    let price = 0;
    products.map((item) => {
      price += item.price * item.quantity;
      return financial(price);
    });
    setTotalAmt(price);
  }, [products]);
  useEffect(() => {
    if (totalAmt <= 200) {
      setShippingCharge(30);
    } else if (totalAmt <= 400) {
      setShippingCharge(25);
    } else if (totalAmt > 401) {
      setShippingCharge(20);
    }
  }, [totalAmt]);

  return (
    <div className="max-w-full mx-auto px-4">
      <Breadcrumbs title="Cart" />
      {products.length > 0 ? (
        <div className="pb-2">
          <div className="w-full h-20 text-lightText hidden lgl:grid grid-cols-5 place-content-center px-6 text-lg  font-semibold">
            <h2 className="col-span-2">Product</h2>
            <h2>Price</h2>
            <h2>Quantity</h2>
            <h2>Sub Total</h2>
          </div>
          <div className="mt-5 text-darkColorFlop dark:text-white ">
            {products.map((item, index) => (
              <div key={index}>
                <ItemCard item={item} />
              </div>
            ))}
          </div>

          <button
            onClick={() => dispatch(resetCart())}
            className="py-2 px-10 bg-red-500 text-white font-semibold uppercase mb-4 hover:bg-red-700 duration-300 rounded"
            style={{ backgroundColor: currentColor }}
          >
            Reset cart
          </button>

          <div className="max-w-7xl gap-4 flex justify-end mt-4">
            <div className="w-96 flex flex-col gap-4 text-darkColorFlop dark:text-white">
              <h1 className="text-2xl font-semibold text-right">Cart totals</h1>
              <div>
                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium rounded-t">
                  Subtotal
                  <span className="font-semibold tracking-wide ">
                    EGY {financial(totalAmt)}
                  </span>
                </p>
                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                  Shipping Charge
                  <span className="font-semibold tracking-wide ">
                    EGY {shippingCharge}
                  </span>
                </p>
                <p className="flex items-center justify-between border-[1px] border-gray-400 py-1.5 text-lg px-4 font-medium">
                  Discount
                  <span className="font-bold tracking-wide text-lg ">
                    EGY {financial(discountAmt)}
                  </span>
                </p>
                <p className="flex items-center justify-between border-[1px] border-gray-400 py-1.5 text-lg px-4 font-medium rounded-b">
                  Total
                  <span className="font-bold tracking-wide text-lg ">
                    EGY {financial(calculateTotal() + shippingCharge)}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-80 rounded-lg p-4 mx-auto drop-shadow-lg animate-bounce2"
              src={emptyCart}
              alt="emptyCart"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8  flex gap-4 flex-col items-center rounded-md shadow-lg bg-main-light-bg  dark:bg-primeColor">
            <h1 className=" text-xl font-bold uppercase  text-darkColorFlop dark:text-white">
              Your Cart feels lonely.
            </h1>
            <p className="text-sm text-center px-10 -mt-2 text-darkColorFlop dark:text-white">
              Your Shopping cart lives to serve. Give it purpose - fill it with
              T-Shirts, Polo, Wallets, etc. and make it happy.
            </p>
            <Link to="/shop">
              <button
                className="high-light bg-lightText rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2  font-semibold text-lg text-gray-200 hover:text-white duration-300"
                style={{ backgroundColor: currentColor }}
              >
                Continue Shopping
              </button>
            </Link>
          </div>
        </motion.div>
      )}
      <PayMob
        totalAmt={totalAmt}
        products={products}
        shippingCharge={shippingCharge}
      />
    </div>
  );
};

export default Cart;
