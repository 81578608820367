import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import toast from "react-hot-toast";

const State = () => {
  const products = useSelector((state) => state.flopReducer.products);

  const userInfo = useSelector((state) => state.flopReducer.userInfo);
  const userId = useSelector((state) => state.flopReducer.user.uid);
  console.log("🚀 ~ State ~ userId:", userId)

  const queryString = new URLSearchParams(window.location.search);

  const transactionId = queryString.get("id");
  const orderId = queryString.get("order");
  const success = queryString.get("success") === "true"; // Convert string to boolean
  const pending = queryString.get("pending") === "true"; // Convert string to boolean
  const amountCents = queryString.get("amount_cents");
  const sourceDataSubType = queryString.get("source_data.sub_type");
  const sourceDataType = queryString.get("source_data.type");
  const updatedAt = queryString.get("updated_at");

  useEffect(() => {

     // Prepare the codes array from products
     const codes = products.map((product) => ({
      code: product.code, // Adjust this to match the actual product code key
      quantity: product.quantity,
    }));
      console.log("🚀 ~ codes ~ codes:", codes)
    if (success) {
      console.log("🚀 ~ useEffect ~ success:", success);
      const transactionDatasupa = {
        transactionId,
        success,
        pending,
        amount: amountCents / 100,
        orderId,
        updatedAt: updatedAt || null,
        sourceDataType: sourceDataType || null,
        sourceDataSubType: sourceDataSubType || null,
        userInfo: { ...userInfo },
        userId: userId,
        products: { ...products },
        codes: [ ...codes ],
      };

      fetch(
        "https://osuuggvwikirmwtaycon.supabase.co/functions/v1/ordersData",
        {
          method: "POST",
          body: JSON.stringify(transactionDatasupa),
        }
      )
        .then((response) => {
          console.log("🚀 ~ useEffect ~ response:", response);
          if (response.ok) {
            toast.success(
              `Document written with ID: ${transactionDatasupa.orderId}`
            );
          }
          return response.json();
        })
        .then((data) => console.log("Success:", data))
        .catch(() => console.error(toast.error("Duplicated Order")));

        fetch("https://osuuggvwikirmwtaycon.supabase.co/functions/v1/sendEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(transactionDatasupa),
        })
          .then((response) => {
            if (response.ok) {
              toast.success("Email sent successfully!");
              return response.json();
            }
            throw new Error("Failed to send email");
          })
          .then((data) => console.log("Success:", data))
          .catch((error) => {
            console.error("Error:", error);
            toast.error("Failed to send email");
          });
     
        fetch(
        "https://osuuggvwikirmwtaycon.supabase.co/functions/v1/updateCopyDB",
        {
          method: "POST",
          body: JSON.stringify({codes}),
        }
      )
        .then((response) => {
          console.log("🚀 ~ useEffect ~ response:", response);
          if (response.ok) {
            toast.success("Document written successfully!");
          }
          return response.json();
        })
        .then((data) => console.log("Success:", data))
        .catch(() => console.error(toast.error("Duplicated Order")));
    } else if (pending) {
      toast.error("Transaction pending...");
    }
  }, [success]);

  return (
    <>
      {success ? (
        <div className="relative flex justify-center  ">
          <div className="flex flex-col justify-center items-center  bg-primeColor text-white p-4 rounded-md mx-44 sm:mx-10 my-2 lg:text-lg  lg:font-semibold  sm:font-semibold sm:text-xs">
            <p>Success! Your action was completed.</p>
            <h1>Check Your Inbox For Your Invoice.</h1>
            <p>Your Order Will Be Delivered In 3-5 Days.</p>
          </div>
        </div>
      ) : (
        <div className="relative flex justify-center  ">
          <div className="flex flex-col justify-center items-center  bg-primeColor text-white p-4 rounded-md mx-44 sm:mx-10 my-2 lg:text-lg  lg:font-semibold  sm:font-semibold sm:text-xs">
            <h1>We're sorry</h1>
            <p>
              your order could not be processed at this time. Please try again
              later or contact us for assistance.
            </p>
          </div>
        </div>
      )}
      <div>
        {products.map((product, index) => (
          <div
            key={index}
            className="flex justify-center text-black dark:text-white"
          >
            <div
              key={index}
              className=" flex lg:w-1/2 justify-start content-center  items-center p-4  sm:mx-10 my-2 bg-white/5 rounded-md shadow-md sm:w-full  sm:p-1  hover:scale-[101%] duration-300 ease-in-out"
            >
              <div className="flex justify-between items-center ">
                <img
                  src={product.images}
                  className="w-32 h-32  rounded mr-4 "
                  alt={product.name}
                />
                <div className="flex flex-col justify-evenly items-start p-2 gap-4">
                  <h2 className="lg:text-lg  lg:font-semibold sm:font-semibold sm:text-xs  ">
                    {product.name}
                  </h2>

                  <p className="lg:text-lg  lg:font-semibold  sm:font-semibold sm:text-xs ">
                    {product.price} EGP
                  </p>
                  <p className=" lg:text-lg  lg:font-semibold  sm:font-semibold sm:text-xs">
                    Quantity : {product.quantity}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}

        {success && (
          <div className="flex  justify-center text-black dark:text-white">
            <div className="flex lg:w-1/2 sm:min-w-max justify-evenly gap-5 content-center  items-center p-4 mx-44 sm:mx-10 my-2 bg-white/5 rounded-md shadow-md sm:w-full hover:scale-[101%] duration-300 ease-in-out">
              <div className="lg:text-lg  lg:font-semibold  sm:font-semibold sm:text-xs">
                <h1> Total Amount:</h1>
                <h1> Order ID:</h1>
                <p>Transaction State:</p>
              </div>

              <div className="lg:text-lg  lg:font-semibold  sm:font-semibold sm:text-xs">
                <>
                  <h1 className="flex justify-center items-center text-center">
                    {" "}
                    {`Total Amount ${amountCents / 100} EGP`}
                  </h1>
                  <h1> {`Your Order ID is ${orderId}`}</h1>
                  <p>Transaction successful!</p>
                </>
                {pending && <p>Transaction pending...</p>}
              </div>
            </div>
          </div>
        )}
        {success && (
          <div className="relative flex justify-center  ">
            <div className="flex flex-col justify-center items-center  bg-primeColor text-white p-4 rounded-md mx-44 sm:mx-10 mt-2">
              <div className="flex flex-col items-center ">
                {userInfo && (
                  <>
                    <h2 className="lg:text-lg  lg:font-semibold  sm:font-extrabold sm:text-xs">
                      User Information
                    </h2>
                    <p className="lg:text-lg  lg:font-semibold  sm:font-medium sm:text-xs">
                      {" "}
                      <span className="lg:text-lg  lg:font-semibold  sm:font-bold sm:text-xs">
                        Name:
                      </span>{" "}
                      {userInfo.first_name} {userInfo.last_name}
                    </p>
                    <p className="lg:text-lg  lg:font-semibold  sm:font-medium sm:text-xs">
                      <span className="lg:text-lg  lg:font-semibold  sm:font-bold sm:text-xs">
                        Email:
                      </span>{" "}
                      {userInfo.email}
                    </p>
                    <p className="lg:text-lg  lg:font-semibold  sm:font-medium sm:text-xs">
                      <span className="lg:text-lg  lg:font-semibold  sm:font-bold sm:text-xs">
                        Mobile:
                      </span>{" "}
                      {userInfo.phone}
                    </p>
                    <p className="lg:text-lg  lg:font-semibold  sm:font-medium sm:text-xs">
                      <span className="lg:text-lg  lg:font-semibold  sm:font-bold sm:text-xs">
                        Address:{" "}
                      </span>{" "}
                      {userInfo.city} {userInfo.address} {userInfo.street}{" "}
                      {userInfo.building} Floor {userInfo.floor}{" "}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default State;
