import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Help = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4 text-black dark:text-white ">
      <Breadcrumbs title="Help" prevLocation={prevLocation} />
      <div className="pb-10">
        <h1 className="max-w-[600px] text-base  mb-2">
        <span className="text-flopdarkColor font-semibold text-lg">FLOP Stores</span> {" "}
          Our company has over 15 years of experience in providing high-quality products and services that meet customer needs and work to satisfy customers
        </h1>
        
       <div className="flex flex-col gap-4">
       <Link to="/refund-and-exchange">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
          Refund and exchange
          </button>
        </Link>
        <Link to="/privacy-policy">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
          Privacy policy
          </button>
        </Link>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
       </div>
      </div>
    </div>
  );
};

export default Help;
