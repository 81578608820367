import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import FullPageLoader from "../../components/designLayouts/FullPageLoader";

const OrdersComponent = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const userId = useSelector((state) => state.flopReducer.user?.uid); // Optional chaining to handle null

  useEffect(() => {
    if (!userId) {
      setError(new Error("User ID is not available"));
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://osuuggvwikirmwtaycon.supabase.co/functions/v1/ordersData/${userId}`
        );

        console.log("🚀 ~ fetchData ~ response:", response.data);
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.error("There was an error fetching the orders!", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]); // Include userId in the dependency array

  if (loading) return <FullPageLoader />;
  if (error) return <div>Error loading data: {error.message}</div>;

  return (
    <div className="bg-[#f0f0f0] dark:bg-[#1a1a1a] text-[#333] dark:text-[#ccc] min-h-screen p-4 rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-[#104d9c]">Orders</h1>
      {orders.length > 0 ? (
        orders.map((order, index) => (
          <div
            key={index}
            className="bg-[#ffffff] dark:bg-[#2c2c2c] p-4 rounded-lg shadow-md mb-6"
          >
            <h2 className="text-xl font-semibold mb-2">
              Order ID: {order.orderId}
            </h2>
            <p className="text-lg mb-2">Amount: {order.amount} EGP</p>
            <p className="mb-2">Paid With: {order.sourceDataSubType.toUpperCase()}</p>
            <p className="mb-2">Date: {order.updatedAt}</p>
            <p className="mb-4">Pending: {order.pending ? "Yes" : "No"}</p>
            <div>
              <h3 className="text-lg font-medium mb-2">Products:</h3>
              {typeof order.products === "object" && order.products !== null ? (
                Object.keys(order.products).map((key) => {
                  const product = order.products[key];
                  return (
                    <div
                      key={key}
                      className="bg-[#f9f9f9] dark:bg-[#3a3a3a] p-4 rounded-lg shadow-sm mb-4"
                    >
                      <h4 className="text-md font-semibold mb-2">
                        {product.name}
                      </h4>
                      <p className="mb-2">Price: {product.price} EGP</p>
                      <p className="mb-2">Code: {product.code}</p>
                      <p className="mb-2">Quantity: {product.quantity}</p>
                      <p className="mb-2">Size: {product.size}</p>
                      <p className="mb-2">Color: {product.color}</p>

                      <img
                        src={product.images}
                        alt={product.name}
                        className="w-full h-auto rounded-md shadow-md"
                      />
                    </div>
                  );
                })
              ) : (
                <p>No products found for this order</p>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>No orders found</p>
      )}
    </div>
  );
};

export default OrdersComponent;
