import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";

const Heading = ({ heading }) => {
  const { currentMode } = useStateContext();
  return (
    <div className={currentMode === "Dark" ? "dark" : "light"}>
      <div className="text-3xl font-semibold pb-6 pt-6 dark:text-lightColor">
        {heading}
      </div>
    </div>
  );
};

export default Heading;
