import React, { useState, useEffect } from "react";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { db, storage, auth } from "../../firebase";
import FullPageLoader from "../../components/designLayouts/FullPageLoader.jsx";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { signOut as firebaseSignOut } from "firebase/auth";
import toast from "react-hot-toast";
import Header from "../../components/home/Header/Header";
import { flopLogoDark } from "../../assets/images";
import "../../App.css";
import "./UserProfilePic.css";
import OrdersList from "./OrdersList";

async function fetchDocument(collectionName, documentId) {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists) {
      const documentData = docSnap.data();

      return documentData;
    } else {
      toast.log("No document found for the provided ID.");
      return null;
    }
  } catch (error) {
    toast.error("Error fetching document:", error);
    return null;
  }
}

const UserProfilePic = () => {
  const [imgUrl, setImgUrl] = useState(flopLogoDark);
  const [name, setName] = useState(localStorage.getItem("email"));
  const [uploading, setUploading] = useState(false);
  const [user, setUser] = useState(null);
  const [editingName, setEditingName] = useState(false);
  const [newName, setNewName] = useState(localStorage.getItem(""));
  const navigate = useNavigate();
  const { currentColor } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      setUser(authUser);
      setIsLoading(false);
  
      if (authUser) {
        const { uid } = authUser;
        const fetchedDoc = await fetchDocument("userProfile", uid);
        localStorage.setItem(`profileName_${uid}`, fetchedDoc.name);
        localStorage.setItem(`profileImageUrl_${uid}`, fetchedDoc.imgUrl);
  
        const storedImgUrl = localStorage.getItem(`profileImageUrl_${uid}`) || fetchedDoc.imgUrl;
        const storedName = localStorage.getItem(`profileName_${uid}`) || fetchedDoc.name;
  
        if (storedImgUrl && storedName && uid) {
          setIsLoading(true);
          setImgUrl(storedImgUrl);
          setName(storedName);
          setIsLoading(false);
        } else {
          const userDocRef = doc(db, "userProfile", uid);
          try {
            const docSnap = await getDoc(userDocRef);
            if (docSnap.exists) {
              const data = docSnap.data();
              setImgUrl(data.imgUrl);
              setName(data.name);
              localStorage.setItem(`profileImageUrl_${uid}`, data.imgUrl);
              localStorage.setItem(`profileName_${uid}`, data.name);
            }
          } catch (error) {
            toast.error(`Error fetching user data: ${error.message}`);
          }
        }
      } else {
        setImgUrl(null);
        setName(null);
        // Clear all profile-related items from localStorage
        Object.keys(localStorage).forEach((key) => {
          if (key.startsWith('profileImageUrl_') || key.startsWith('profileName_')) {
            localStorage.removeItem(key);
          }
        });
      }
    });
  
    return () => unsubscribe();
  }, []);
  

  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const storageRef = ref(storage, `profileImages/${user.uid}`);
      setUploading(true);
      setIsLoading(true);

      uploadBytes(storageRef, file)
        .then(async (snapshot) => {
          const downloadUrl = await getDownloadURL(snapshot.ref);
          const userDocRef = doc(db, "userProfile", user.uid);
          setIsLoading(false);
          await setDoc(
            userDocRef,
            { imgUrl: downloadUrl, name: user.displayName },
            { merge: true }
          );
          await getDoc(userDocRef)
            .then((doc) => {
              if (doc.exists) {
               
              } else {
                toast.error("No such document!");
              }
            })
            .catch((error) => {
              toast.error("Error getting document:", error);
            });

          setImgUrl(downloadUrl);
          setUploading(false);
          localStorage.setItem(`profileImageUrl_${user.uid}`, downloadUrl);
          toast.success("Profile image Uploaded successfully!");
        })
        .catch((error) => {
          toast.error("Failed to upload profile image.", error);
        });
    }
  };

  const handleEditName = () => {
    setEditingName(true);
  };

  const handleSaveName = async () => {
    const userDocRef = doc(db, "userProfile", user.uid);

    try {
      setIsLoading(true);
      await setDoc(userDocRef, { name: newName }, { merge: true });
      localStorage.setItem(`profileName_${user.uid}`, newName);

      setEditingName(false);
      setName(newName);
      toast.success("Name updated successfully!");
      setIsLoading(false);
    } catch (error) {
      toast.error("Error updating name:", error);
    }
  };

  const handleDelete = async () => {
    const userDocRef = doc(db, "userProfile", user.uid);

    try {
      setIsLoading(true);
      await deleteDoc(userDocRef);

      localStorage.removeItem(`profileImageUrl_${user.uid}`);
      setImgUrl(null);

      
      toast.success("Profile image deleted successfully!");
      setIsLoading(false);
    } catch (error) {
      toast.error("Error deleting document:", error);
    }
  };

  const signOut = () => {
    setIsLoading(true);
    firebaseSignOut(auth)
      .then(() => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("email");
        localStorage.removeItem("gmail");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userName");
        localStorage.removeItem("userNumber");
        localStorage.removeItem("mobile");
        localStorage.removeItem("uid");
        localStorage.removeItem(`profileImageUrl_${user.uid}`);
        localStorage.removeItem(`profileName_${user.uid}`);
        setIsLoading(false);

        navigate("/");
      })
      .catch((error) => {
        toast.error("Error signing out: ", error);
      });
  };

  return (
    <>
      <div className="w-[100vw] h-[100vh]">
        {isLoading && (
          <FullPageLoader bg="rgba(255, 255, 255, 0.8)"></FullPageLoader>
        )}
        <Header />
        <div className="container flex justify-center content-center mx-auto max-w-screen-lg h-screen  p-4 dark:text-white">
          {user && !isLoading ? (
            <div>
              <h1 className="text-xl font-bold mb-2 relative flex justify-center content-center text-black dark:text-white">
                Profile Picture
              </h1>
              <p
                className="relative flex justify-center shadow-sm  shadow-gray-400 dark:shadow-slate-200 rounded-full overflow-hidden p-2 m-2 text-white"
                style={{
                  backgroundColor: `${currentColor}`,
                }}
              >
                {name || localStorage.getItem("email")}
              </p>
              {uploading && (
                <p className="relative flex justify-center content-center">
                  Uploading...
                </p>
              )}

              {imgUrl ? (
                <>
                  <div className="flex relative mx-auto w-[100px] h-[100px] bg-white items-center justify-center shadow-sm  shadow-gray-400 dark:shadow-slate-200  rounded-full overflow-hidden">
                    <img
                      src={imgUrl}
                      alt="Profile"
                      className=" object-fill w-full  "
                    />
                  </div>
                </>
              ) : (
                <div className="flex relative mx-auto w-[100px] h-[100px] bg-white items-center justify-center shadow-sm  shadow-gray-400 dark:shadow-slate-200  rounded-full overflow-hidden">
                  <img
                    src={flopLogoDark}
                    alt="Profile"
                    className=" object-contain w-[100px] h-[100px]"
                  />
                </div>
              )}
              <div className="relative flex justify-center items-center content-center">
                {editingName ? (
                  <>
                    <input
                      type="text"
                      placeholder="Enter your name"
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      className="p-2 m-2 text-center bg-black text-white rounded-lg"
                    />
                    <button
                      className="p-2 rounded-lg text-white"
                      onClick={handleSaveName}
                      style={{
                        backgroundColor: `${currentColor}`,
                      }}
                    >
                      Save Name
                    </button>
                  </>
                ) : (
                  <>
                    <div
                      className="relative   w-auto rounded-[5px] p-2 top-4 shadow-sm  shadow-gray-400 dark:shadow-slate-200 "
                      style={{
                        backgroundColor: `${currentColor}`,
                      }}
                    >
                      <button className="text-white " onClick={handleEditName}>
                        Edit Name
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="custom-file-upload relative flex flex-col items-center p-4 ">
                <label
                  className="shadow-sm  shadow-gray-400 dark:shadow-slate-200 "
                  htmlFor="file-upload"
                  style={{
                    backgroundColor: `${currentColor}`,
                  }}
                >
                  Choose File
                </label>

                <input
                  type="file"
                  id="file-upload"
                  accept="image/*"
                  onChange={handleUpload}
                />
                <button
                  onClick={handleDelete}
                  className="relative font-bodyFont text-[32px] text-red-500 p-2  "
                >
                  <RiDeleteBin6Fill
                    style={{
                      color: `${currentColor}`,
                    }}
                  />
                </button>
              </div>
             <OrdersList />
              <div className="relative flex justify-center items-center content-center">
                <button
                  className="absolute flex justify-center item-center submit  shadow-sm  shadow-gray-400 dark:shadow-slate-200 -bottom-80"
                  align="center"
                  onClick={signOut}
                  style={{
                    backgroundColor: `${currentColor}`,
                  }}
                >
                  Sign out
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-start items-center">
                <p>Please sign in to view and upload a profile picture.</p>
                <button
                  className="cursor-pointer bg-slate-700 rounded-md p-2 text-white"
                  onClick={() => navigate("/signin")}
                >
                  Please Login
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfilePic;
