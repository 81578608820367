import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import { paginationItems } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../../redux/productSlice"; // Assuming productsSlice is your slice file

import Heading from "../../home/Products/Heading";
import { flopLogoDark } from "../../../assets/images";
import "aos/dist/aos.css";

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div key={item.barCode} className="w-full">
            <Product
              _id={item.barCode}
              barCode={item.fullName}
              productName={item.barCode}
              code={item.variants[0].code}
              codes={item.codes}
              color={item.variants[0].color}
              colors={item.colors}
              price={item.price}
              brand={item.category}
              size={item.variants[0].sizeName}
              sizes={item.sizes}
              desc={item.season}
              quantity={item.variants[0].quantity}
              img={
                item?.variants[0].imagesUrls?.[0]
                  ? item.variants[0].imagesUrls?.[0]
                  : flopLogoDark
              }
              images={
                item?.variants[0].imagesUrls
                  ? item?.variants[0].imagesUrls
                  : [flopLogoDark]
              }
            />
          </div>
        ))}
    </>
  );
}

const Pagination = ({ itemsPerPage }) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.productsReducer.items);
  // console.log("🚀 OnSale ~ ProductsListRedux ~ products:", products);
  const status = useSelector((state) => state.productsReducer.status);
  const error = useSelector((state) => state.productsReducer.error);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchProducts())
        .unwrap()
        .catch((err) => {
          if (err !== "Data is still fresh") {
            console.error("Failed to fetch products:", err);
          }
        });
    }
  }, [dispatch, status]);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);

  const endOffset = itemOffset + itemsPerPage;

  const currentItems = products.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(products.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;
    setItemOffset(newOffset);

    setItemStart(newOffset);
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
        <Items currentItems={currentItems} />
      </div>
      <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
        <ReactPaginate
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel=""
          pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
          pageClassName="mr-6"
          containerClassName="flex text-base font-semibold  py-10"
          activeClassName="bg-black text-white"
        />

        <p className="text-base font-normal text-lightText">
          Products from {itemStart === 0 ? 1 : itemStart} to {endOffset} of{" "}
          {products.length}
        </p>
      </div>
    </div>
  );
};

export default Pagination;
