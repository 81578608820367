import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import "./Banner.css"

import {
  bannerImgOne,
  bannerImgTwo,
  bannerImgThree,
  bannerImgFour,
  bannerImgFive,
  bannerImgSix,
} from "../../assets/images";
import Image from "../designLayouts/Image";

import "aos/dist/aos.css";

const Banner = () => {
  const Banners = [
    { id: 1, img: bannerImgOne, title: "Banner One"},
    { id: 2, img: bannerImgTwo, title: "Banner Two" },
    { id: 3, img: bannerImgThree , title: "Banner Three" },
    { id: 4, img: bannerImgFour , title: "Banner Four"},
    { id: 5, img: bannerImgFive , title: "Banner Five"},
    { id: 6, img: bannerImgSix , title: "Banner Six"},
  ];

  const splideOptions = {
    perPage: 1,
    perMove: 1,
    type: "slide",
    rewind: true,
    keyboard: "global",
    gap: "1rem",
    pagination: true,
    padding: "2rem",
    autoplay: "true",

    breakpoints: {
      1920: { perPage: 3 },
      1200: { perPage: 3 },
      991: { perPage: 1 },
      768: { perPage: 1 },
      500: { perPage: 1 },
      425: { perPage: 1 },
      375: { perPage: 1 },
    },
  };

  return (
    <div className="w-full" data-aos="flip-left"
    data-aos-easing="ease-out-quad"
    data-aos-duration="2000">
      <Splide options={splideOptions}>
          {Banners.map((banner) => (
        <SplideSlide key={banner.id} className="mb-0.5 pb-2">
            <Image
              imgSrc={banner.img}
              alt={banner.title}
              className="w-full h-auto object-cover shadow-md shadow-flopdarkColor/50 rounded-lg  hover:scale-[110%] "
            />
        </SplideSlide>
          ))}
      </Splide>
    </div>
  );
};

export default Banner;
