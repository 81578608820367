import React, { useEffect, useRef } from "react";
import Viewer from "viewerjs";
import "./Zoom.css";

const ProductImageGallery = ({ images }) => {
  const galleryRef = useRef(null);

  useEffect(() => {
    if (galleryRef.current) {
      const viewer = new Viewer(galleryRef.current, {
        // inline: true,
        fading: true,
        interval:3000,

        viewed() {},
      });

      return () => {
        viewer.destroy();
      };
    }
  }, [images]);

  if (!images || images.length === 0) {
    return null;
  }

  return (
    <div>
      <div ref={galleryRef}>
        <img
          src={images[0]}
          alt="Main Product"
          className="shadow-lg  dark:shadow-slate-300/5"
          style={{
            maxWidth: "100%",
            maxHeight: "80vh",
            borderRadius: "10px",
            margin: "5px",
          }}
        />
        <div className="flex justify-evenly flex-wrap content-center">
          {images.slice(1).map((img, index) => (
            <img
              key={index + 1}
              src={img}
              alt={`Thumbnail ${index + 1}`}
              className="shadow-lg  dark:shadow-slate-300/5 flex hover:scale-110"
              style={{
                maxWidth: "90px",
                borderRadius: "10px",
                margin: "5px",
                cursor: "pointer",
                display: "flex",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductImageGallery;
