import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import { useStateContext } from "../../../contexts/ContextProvider";
import { truncate } from "lodash";

function Items({ currentItems }) {
  const [hoveredProduct, setHoveredProduct] = useState(null);
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div
            key={item.id}
            className="w-full"
            onMouseEnter={() => setHoveredProduct(item.id)}
            onMouseLeave={() => setHoveredProduct(null)}
          >
            <Product
              _id={item.id}
              img={hoveredProduct === item.id ? item.images[1] : item.images[0]}
              productName={item.name}
              price={item.price}
              color={item.color}
              brand={item.brand}
              sizes={item.sizes}
              colors={item.colors}
              code={item.code}
              barCode={item.barCode}
              category={item.category}
              badge={true}
              badgeorg={true}
              desc={truncate(item.desc, { length: 30 })}
              images={item.images.length > 1 ? item.images : []}
              quantity={item.quantity}
            />
          </div>
        ))}
    </>
  );
}

const itemsPerPage = 12;

const Pagination = ({ items }) => {
  const { currentMode } = useStateContext();

  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);

  const endOffset = itemOffset + itemsPerPage;

  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);

    setItemStart(newOffset);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={currentMode === "Dark" ? "dark" : "light"}>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
        <Items currentItems={currentItems} />
      </div>
      <div className="flex justify-center items-center">
        <ReactPaginate
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          nextClassName="px-1 mx-1 text-black dark:text-lightColor border-[1px] border-gray-300 dark:border-lightColor rounded hover:border-gray-500 dark:hover:border-gray-500 duration-300 flex justify-center items-center sm:w-auto"
          previousClassName="px-1 mx-1 text-black dark:text-lightColor border-[1px] border-gray-300 dark:border-lightColor rounded hover:border-gray-500 dark:hover:border-gray-500 duration-300 flex justify-center items-center sm:w-auto"
          pageLinkClassName="w-9 h-9 border-[1px] border-border-gray-300 dark:border-lightColor dark:text-lightColor dark:hover:border-gray-500 rounded hover:border-gray-500 duration-300 flex justify-center items-center"
          pageClassName="mr-1 ml-1"
          containerClassName="flex text-base font-semibold   py-2 sm-w-1/2  "
          activeClassName="bg-main-flop-bg"
        />
      </div>
      <div className="flex justify-center">
        <p className="text-base font-normal text-black dark:text-lightColor">
          Products from {itemStart === 0 ? 1 : itemStart} to{" "}
          {Math.min(endOffset, items.length)} of {items.length}
        </p>
      </div>
    </div>
  );
};

export default Pagination;
