import React from "react";
import { AiOutlineCopyright } from "react-icons/ai";

import "aos/dist/aos.css";
import { useStateContext } from "../../../contexts/ContextProvider";

const FooterBottom = () => {
  const { currentMode } = useStateContext();

  return (
    <div className={currentMode === "Dark" ? "dark" : "light"}>
      <div className="w-full  group ">
        <div className="max-w-container mx-auto border-t-[1px] pt-5 pb-5 ">
          <p className="font-normal text-center flex md:items-center justify-center text-lightText dark:text-lightColor duration-200 text-sm">
            <span className="text-md mr-[1px] mt-[2px] md:mt-0 text-center hidden md:inline-flex">
              <AiOutlineCopyright />
            </span>
            Copyright 2024 | FLOP STORES © | All Rights Reserved
            <a href="https://flopstores.com" target="_blank" rel="noreferrer">
              <span className="ml-1 font-medium ">
                Powered by Flopstores.com
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
